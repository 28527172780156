import React, { useEffect, Suspense, lazy } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { observer, Provider as MobxProvider } from "mobx-react";

import "./App.scss";
import StoreMobx from "./store/mobx/Store";
import { ShareLinkHandler } from "./components/ShareLink";
import { DeviceController } from "./scripts/hooks/DeviceController";
import { Synchronizator } from "./components/Synchronizator";
import amplitude from "amplitude-js";
import { AmplitudeProvider } from "react-amplitude-hooks";
import "@nalezhytyi/saldo-ui/dist/cjs/index.css";
import routes from "./routes";
import authStore from "./store/mobx/AuthStore";
import settingsStore from "./store/mobx/SettingsStore";
import { Loader } from "@nalezhytyi/saldo-ui";
import { Paywall } from "./components/Paywall";
import { SendEmailModalHandler } from "./components/SendEmailModal";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { Helmet } from "react-helmet";
import Intercom from "utils/intercomManager";
import TokenManager from "./manager/token.manager";
import LocalStorageManager from "manager/local-storage.manager";
import { ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css'
import UpdateModal from "components/UpdateModal";
import ImportantUpdateModal from "components/ImportantUpdateModal";
import SignUpFullScreen from "components/Auth/SignUpFullScreen";

const Auth = lazy(() => import("components/Auth"));
const Home = lazy(() => import("components/Home"));

Bugsnag.start({
  apiKey: "9b3bc6815cf4d9e5a1acdbec7e1933c9",
  plugins: [new BugsnagPluginReact()],
});
// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const App = observer(() => {
  const amplitudeUserId = authStore.user?.customerUid;
  const amplitudeKey = process.env.REACT_APP_AMPLITUDE_KEY || ''
  const isSignUpFullScreen = window.location.pathname?.includes("/sign-up-full-screen")

  const [isInitialization, setIsInitialization] = React.useState(false)

  useEffect(() => {
    authStore.init().finally(() => {
      setIsInitialization(true)
    })

    if (!isSignUpFullScreen) {
      Intercom.boot();
    }
  }, [])

  if (!isInitialization) {
    return <Loader backdrop center size="md" />
  }

  return (
    <ErrorBoundary>
      <DeviceController />
      <Helmet>
        <title>Saldo Apps</title>
        <meta name="description" content="Invoice Your Customers" />
      </Helmet>
      <MobxProvider {...StoreMobx}>
        <AmplitudeProvider
          userId={amplitudeUserId}
          amplitudeInstance={amplitude.getInstance()}
          apiKey={amplitudeKey}
          config={{
            includeUtm: true,
            includeReferrer: true,
          }}
        >
          <Susp />
          <Synchronizator />
          <Paywall />
          {!isSignUpFullScreen && (
            <>
              <ImportantUpdateModal />
              <UpdateModal />
            </>
          )}
          <ToastContainer
            hideProgressBar
            theme="colored"
            autoClose={3000}
            closeOnClick={false}
            position="bottom-right"
          />
          <ShareLinkHandler />
          <SendEmailModalHandler />
        </AmplitudeProvider>
      </MobxProvider>
    </ErrorBoundary>
  );
});

const Susp = () => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const _s = window.location.search;
      const _URLSP = new URLSearchParams(_s);

      const __token = _URLSP.get("t") || "";
      const __rToken = _URLSP.get("r") || "";
      const __aType = _URLSP.get("aType") || "";
      const __docType = _URLSP.get("docType" || "");
      const __docId = _URLSP.get("docId") || "";
      const token = TokenManager.getAccessToken()
      if (__token && window.location.pathname?.includes("/sign-in")) {
        TokenManager.set({accessToken: __token, refreshToken: __rToken})
        LocalStorageManager.set("signInType", __aType);

        _URLSP.delete("t");
        _URLSP.delete("r");
        history.push(`/auth/sign-in?${_URLSP.toString()}`);

        await settingsStore.clearSettings();
        await authStore.resetUser();
      } else if (!token) {
        if (
          !window.location.pathname?.includes("/sign-in") &&
          !window.location.pathname?.includes("/sign-up")
        ) {
          history?.push(routes.signIn);
        }
      } else if (!authStore.user?.email) {
        await authStore.getUser();

        await settingsStore.getSettings();

        if (__docType && __docId) {
          history.push(`/generator/${__docType}/${__docId}`);
        }
      }
    })();
  }, [authStore.user]);

  return (
    <Suspense fallback={<Loader center backdrop size="lg" />}>
      <Switch>
      <Route path={routes.signUpFullScreen} component={SignUpFullScreen} />
        <Route path={routes.auth} component={Auth} />
        <Route path={routes.home} component={Home} />
      </Switch>
    </Suspense>
  );
};

export default App;
