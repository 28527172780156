import { jwtDecode } from 'jwt-decode'
import LocalStorageManager from './local-storage.manager'

export type JWTData = {
  accessToken: string
  refreshToken: string
}

const ACCESS_TOKEN_KEY = 'saldo.atoken'
const REFRESH_TOKEN_KEY = 'saldo.rtoken'

const TokenManager = {
  decode(token: string): { exp: number } {
    return jwtDecode(token || '')
  },

  set({ accessToken, refreshToken }: JWTData) {
    const accessTokenData = this.decode(accessToken)
    const refreshTokenData = this.decode(refreshToken)

    if (accessTokenData) {
      LocalStorageManager.set(ACCESS_TOKEN_KEY, accessToken)
    }

    if (refreshTokenData) {
      LocalStorageManager.set(REFRESH_TOKEN_KEY, refreshToken)
    }
  },

  isExpiredAccessToken(): boolean {
    const token = this.getAccessToken()
    if (token) {
      const { exp } = this.decode(token)

      return Date.now() > (exp - 3 * 60) * 1000
    }

    return true
  },

  isExpiredRefreshToken(): boolean {
    return Boolean(this.getRefreshToken()) === false
  },

  getAccessToken(): string | null {
    return LocalStorageManager.get(ACCESS_TOKEN_KEY) || null
  },

  getRefreshToken(): string | null {
    return LocalStorageManager.get(REFRESH_TOKEN_KEY) || null
  },

  clear() {
    LocalStorageManager.delete(ACCESS_TOKEN_KEY)
    LocalStorageManager.delete(REFRESH_TOKEN_KEY)
  },
}

export default TokenManager
