import { ReactNode } from 'react'
import { toast, ToastOptions } from 'react-toastify'

const useNotification = () => {
  return {
    ...toast,
    push: (message: ReactNode | string, options?: ToastOptions) => {
      return toast(message, {
        ...options,
        type: options?.type || toast.TYPE.INFO,
      })
    },
  }
}

export default useNotification
