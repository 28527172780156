import { TaxType } from 'data/documents/types'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { createContext } from 'react'
import { toast } from 'react-toastify'
import fileService from 'services/file-service/file.service'
import industriesService from 'services/industries-service/industries.service'
import { Industry } from 'services/industries-service/types'
import legalEntitiesService from 'services/legal-entities-service/legalEntities.service'
import {
  FilterModel,
  LegalEntity,
  LegalEntityData,
  LegalEntitySuggestionsData,
} from 'services/legal-entities-service/types'
import helpers from 'utils/helpers'
import { UIFormatterType } from 'utils/ui-formatter'
import { v4 as uuidv4 } from 'uuid'

export const initialEditorData: LegalEntity = {
  id: '',
  archived: false,
  accountingEntityId: '',
  address: {
    addressLine1: null,
    addressLine2: null,
    postalCode: null,
    country: null,
    city: null,
    state: null,
    countryIsoCode: null,
  },
  legalName: '',
  email: null,
  logo: null,
  phone: null,
  signature: null,
  website: null,
  taxNumber: null,
  noteForClients: null,
  taxType: TaxType.NONE,
  taxIncluded: false,
  tax: null,
  paymentOptions: {
    check: null,
    paypalEmail: null,
    bankTransfer: null,
    other: null,
    stripeIntegration: {
      stripeAccount: null,
      active: false,
      onBoardingCompleted: false,
      paymentsEnabled: false,
      passFeesToClients: false,
    },
  },
  uiFormatter: UIFormatterType.US_FORMAT,
  currency: null,
  industryGroup: '',
  industryName: '',
  productDescription: null,
}

export class LegalEntitiesStore {
  legalEntitiesData: LegalEntityData
  filterModel: FilterModel
  industriesData: Industry[]
  editorData: LegalEntity
  edited: boolean
  isEditorOpen: boolean
  isLoading: boolean
  stripeSuggestionsData: LegalEntitySuggestionsData | null

  constructor() {
    makeObservable(this, {
      legalEntitiesData: observable,
      filterModel: observable,
      industriesData: observable,
      getLegalEntities: action,
      updateFilter: action,
      postLegalEntity: action,
      clear: action,
      editorData: observable,
      edited: observable,
      isEditorOpen: observable,
      isLoading: observable,
      toggleEditor: action,
      closeEditor: action,
      changeData: action.bound,
      industriesList: computed,
      getIndustries: action,
      stripeSuggestionsData: observable,
      getStripeSuggestions: action,
    })

    this.legalEntitiesData = {
      results: [],
      offset: 0,
      total: 0,
    }
    this.filterModel = {
      sortBy: 'createdAt',
      search: undefined,
      offset: 0,
      limit: 10,
      sortType: 'DESC',
    }

    this.editorData = { ...initialEditorData }
    this.industriesData = []
    this.edited = false
    this.isEditorOpen = false
    this.isLoading = false
    this.stripeSuggestionsData = null
  }

  get industriesList() {
    return this.industriesData.map((industry) => {
      return {
        value: industry.name,
        label: industry.nameText,
        groupLabel: industry.groupText,
        group: industry.group,
      }
    })
  }

  toggleEditor = () => {
    this.isEditorOpen = !this.isEditorOpen
  }

  closeEditor = () => {
    this.edited = false
    this.isEditorOpen = false
  }

  async edit(id?: string | number | undefined) {
    const curItem = this.legalEntitiesData?.results?.find((i) => i.id === id)
    this.editorData = { ...this.editorData, ...curItem }
    this.toggleEditor()
  }

  create() {
    this.editorData = { ...initialEditorData }
    this.toggleEditor()
  }

  changeData(
    value: any,
    key?: keyof LegalEntity,
    nestedKey?: keyof LegalEntity[keyof LegalEntity]
  ) {
    this.edited = true
    if (!key) return

    if (nestedKey && !this.editorData[key]) {
      this.editorData[key] = {}
    }

    if (key && nestedKey) {
      return (this.editorData[key][nestedKey] = value)
    }
    this.editorData[key] = value
  }

  getArrBySearch = async (search: string) => {
    const data = await legalEntitiesService.getLegalEntities({ search })
    return data?.results.map((el: any) => {
      return {
        label: el.legalName,
        value: el.legalName,
        data: el,
      }
    })
  }

  getIndustries = async () => {
    this.isLoading = true
    try {
      const data = await industriesService.getIndustries()

      runInAction(() => {
        this.industriesData = data
      })
    } catch (error) {
      throw error
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  findLegalEntiryById = (id?: string) => {
    const legalEntity = this.legalEntitiesData?.results?.find(
      (i) => i.id === id
    )
    return legalEntity || {}
  }

  getStripeSuggestions = async () => {
    this.isLoading = true
    try {
      const data = await legalEntitiesService.getStripeSuggestions()

      runInAction(() => {
        this.stripeSuggestionsData = data
      })
    } catch (error) {
      throw error
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async postLegalEntity(
    data: LegalEntity,
    isCreate?: boolean,
    successCreateHandler?: (Logo: boolean, Signature: boolean) => void
  ) {
    this.isLoading = true

    try {
      const _data = { ...data }

      if (data.logo?.includes('base64')) {
        const { formData, newFileName } = helpers.getFormDataFromBase64(
          data.logo
        )
        const logoLink = await fileService.postFile(formData)

        _data.logo = logoLink ? newFileName : undefined
      }
      if (data.signature?.includes('base64')) {
        const { formData, newFileName } = helpers.getFormDataFromBase64(
          data.signature
        )
        const signatureLink = await fileService.postFile(formData)
        _data.signature = signatureLink ? newFileName : undefined
      }

      _data.updatedAt = Date.now()
      _data.createdAt = data.createdAt || Date.now()
      _data.archived = !!data.archived
      _data.id = data.id || uuidv4()
      _data.logo = _data.logo || null
      _data.signature = _data.signature || null
      if (_data.tax) {
        _data.tax.rate = data.tax?.rate ? +data.tax?.rate : 0
      }

      const legalEntity = await legalEntitiesService.postLegalEntity(_data)

      if (_data.archived) {
        toast.info(`company ${_data.businessName} deleted`)
      } else {
        if (isCreate) {
          toast.info(`company ${_data.legalName} created`)
          if (successCreateHandler)
            successCreateHandler(!!_data.logo, !!_data.signature)
        } else {
          toast.info(`company ${_data.legalName} updated`)
        }
      }

      return {
        signature: _data.signature,
        logo: _data.logo,
        legalEntity
      }
    } catch (err) {
      return { error: true }
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async updateFilter(_filterModel?: FilterModel) {
    await this.getLegalEntities(_filterModel)
  }

  async getLegalEntities(_filterModel?: FilterModel) {
    this.isLoading = true

    if (_filterModel) {
      this.filterModel = { ...this.filterModel, ..._filterModel }
    }

    try {
      const data = await legalEntitiesService.getLegalEntities(this.filterModel)
      runInAction(() => {
        this.legalEntitiesData = {
          offset: data?.offset,
          total: data?.total,
          results: data?.results.map((company: LegalEntity) => {
            return {
              ...company,
              address: { ...(company.address || {}) },
            }
          }),
        }
      })
    } catch (error) {
      throw error
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  clear() {
    this.legalEntitiesData = {
      results: [],
      offset: 0,
      total: 0,
    }
  }

  resetFiltration() {
    this.filterModel = {
      sortBy: 'createdAt',
      search: undefined,
      offset: 0,
      limit: 10,
      sortType: 'DESC',
    }
  }
}

const legalEntitiesStore = new LegalEntitiesStore()
export const legalEntitiesStoreContext = createContext(legalEntitiesStore)
export default legalEntitiesStore
