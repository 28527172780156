import { makeObservable, action, observable } from "mobx";
import { createContext } from "react";

export interface IBreakpoint {
  MOBILE: number;
  TABLET_PORT: number;
  TABLET_LAND: number;
  DESKTOP: number;
}

export const BREAKPOINTS: IBreakpoint = {
  MOBILE: 360,
  TABLET_PORT: 960,
  TABLET_LAND: 1024,
  DESKTOP: 1440,
};

export enum DEVICES {
  MOBILE = "MOBILE",
  TABLET_PORT = "TABLET_PORT",
  TABLET_LAND = "TABLET_LAND",
  DESKTOP = "DESKTOP",
}

export class DeviceStore {
  device: DEVICES | null;
  isBroken: boolean;
  collapsed: boolean;

  constructor() {
    makeObservable(this, {
      device: observable,
      isBroken: observable,
      collapsed: observable,
      setDevice: action,
      resetDevice: action,
      setCollapsed: action,
      // setIsBroken: action,
    });

    this.device = null;
    this.isBroken = false;
    this.collapsed = false;
  }

  async setDevice(device: DEVICES) {
    this.device = device;
  }
  async setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed;
  }
  // async setIsBroken(isBroken: boolean) {
  //   this.isBroken = isBroken;
  //   this.collapsed = false;
  // }

  resetDevice() {
    this.device = null;
    this.isBroken = false;
    this.collapsed = false;
  }
}

const deviceStore = new DeviceStore();
export const deviceStoreContext = createContext(deviceStore);
export default deviceStore;
