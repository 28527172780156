import { subscriptionsAPI } from 'services/http.service'

const ENDPOINT = 'v3/subscription/stripe'

class SubscriptionService {
  async subscribe(data: {
    token: string
    price: number
    currency: string
    planId: string
    appSource: string
  }) {
    return await subscriptionsAPI.post(ENDPOINT, {
      data,
    })
  }

  async addCard(data: { token: string; customer: string }) {
    return await subscriptionsAPI.post(`${ENDPOINT}/create-card`, {
      data,
    })
  }

  async subscriptionCancel(data: { subscriptionId: string }) {
    return await subscriptionsAPI.post(`${ENDPOINT}/cancel`, {
      data,
    })
  }

  async updateCard(data: { cardId: string; customer: string }) {
    return await subscriptionsAPI.post(`${ENDPOINT}/update-card`, {
      data,
    })
  }
}

export default new SubscriptionService()
