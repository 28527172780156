import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react'
import clientsStore from 'store/mobx/ClientsStore'
import itemsStore from 'store/mobx/ItemsStore'
import invoicesStore from 'store/mobx/InvoicesStore'
import settingsStore from 'store/mobx/SettingsStore'
import TokenManager from 'manager/token.manager'
import legalEntitiesStore from 'store/mobx/LegalEntitiesStore'

export const Synchronizator: FC = observer(() => {
  useEffect(() => {
    const token = TokenManager.getAccessToken()
    if (!!token) {
      (async () => {
        await Promise.all([
          // settingsStore.getSettings(),
          legalEntitiesStore.getLegalEntities(),
          clientsStore.getClients(),
          itemsStore.getItems(),
          invoicesStore.getDocuments(),
          invoicesStore.getDocAmount(),
          // authStore.getUser(),
        ])
      })()
    }
  }, [settingsStore.settings?.id])

  return <></>
})
