import React, { FC, useEffect, useState } from 'react'
import { Button, Modal, Radio, RadioGroup } from '@nalezhytyi/saldo-ui'
import { observer } from 'mobx-react'
import invoicesStore from 'store/mobx/InvoicesStore'
import infoboxImg from 'assets/images/infobox-img.svg'
import stripeCircleImg from 'assets/images/stripe-circle.svg'
import entityCircleImg from 'assets/images/entity-circle.svg'
import legalEntitiesStore from 'store/mobx/LegalEntitiesStore'
import cn from 'classnames'
import Icon from 'components/Icon'
import paymentStore from 'store/mobx/PaymentStore'
import authStore from 'store/mobx/AuthStore'
import Intercom from 'utils/intercomManager'
import chatIcon from 'assets/images/chat_icon.svg'

import classes from './classes.module.scss'

const UpdateModal: FC = observer(() => {
  const { getStripeSuggestions, stripeSuggestionsData } = legalEntitiesStore
  const { user, isStripeConnected } = authStore
  const { removeGlobalStripeAccount, linkGlobalStripeAccount } = paymentStore
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [legalEntityId, setLegalEntityId] = useState('')
  const [step, setStep] = useState(1)

  if (!isStripeConnected) return null

  const onRemoveStripeAccount = () => {
    removeGlobalStripeAccount(String(user?.customerId))
    setOpenConfirmModal(false)
    setStep(0)
  }

  const onLinkStripeAccount = () => {
    linkGlobalStripeAccount(legalEntityId)
    setStep(0)
  }

  useEffect(() => {
    getStripeSuggestions()
  }, [])

  const renderSuggestions = (suggestedEntities: any[]) => {
    return suggestedEntities.map((entity) => (
      <Radio
        key={entity.id}
        className={cn('mb-8', classes.accountWrapper)}
        value={entity.id}
      >
        <img src={entityCircleImg} alt="entity" />
        <div>
          <div className={classes.name}>{entity.legalName}</div>
          <div className={classes.email}>{entity.email}</div>
        </div>
      </Radio>
    ))
  }

  return (
    <>
      <Modal size="xs" open={openConfirmModal} backdrop>
        <Modal.Body style={{ marginTop: 0 }}>
          <h3>Do you want to disconnect this Stripe Account?</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenConfirmModal(false)}>Cancel</Button>
          <Button appearance="primary" onClick={onRemoveStripeAccount}>
            Dissconect
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="sm"
        className={cn('updateModal', classes.step1)}
        onClose={() => invoicesStore.setIsPaywall(false)}
        open={step === 1}
        backdrop
      >
        <Modal.Header hideCloseIcon>
          <Modal.Title center bold>
            <h1>Important Update!</h1>
            <Button appearance="subtle" size='sm' className={classes.chat} onClick={() => Intercom.show()}>
            <img src={chatIcon} alt="chat" />
          </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={cn('text-center', classes.body)}>
          <div className={classes.subtitle}>
            We&apos;re thrilled to introduce an app update that brings exciting
            features.
          </div>
          <div className={classes.infobox}>
            Now Payment options and tax settings are linked to every business
            entity.
          </div>
          <div className={classes.infobox2}>
            <img src={infoboxImg} />
            <h3>To comply with regulations, we kindly ask you to</h3>
            <div>Link Stripe account to one of the legal entities</div>
            <div>It&apos;ll take no more than 30 seconds</div>
          </div>
          <Button
            onClick={() => setStep(2)}
            appearance="primary"
            block
            size="lg"
          >
            Start
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        className={cn('updateModal', classes.step2)}
        size="sm"
        onClose={() => console.log('11')}
        open={step === 2}
      >
        <Modal.Header hideCloseIcon>
          <Modal.Title center bold className="pos_relative">
            <Button
              size="sm"
              onClick={() => setStep(1)}
              className={classes.backButton}
            >
              <Icon name="regular.chevronLeft" />
            </Button>
            <h2>Link your Stripe account to the Legal Entity</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.body}>
          <label className={classes.label}>Stripe account</label>
          <div
            className={cn(
              'mb-16',
              classes.accountWrapper,
              classes.stripeAccount
            )}
          >
            <img src={stripeCircleImg} alt="stripe" />
            <div>
              <div className={classes.name}>
                {stripeSuggestionsData?.stripe.name}
              </div>
              <div className={classes.email}>
                {stripeSuggestionsData?.stripe.email}
              </div>
            </div>
          </div>
          <RadioGroup
            name="radio-group-controlled"
            value={legalEntityId}
            onChange={(value) => setLegalEntityId(value as string)}
          >
            {!!stripeSuggestionsData?.suggestedLegalEntities.length && (
              <>
                <label className={classes.label}>Suggetions</label>
                <div className={classes.suggestions}>
                  {renderSuggestions(
                    stripeSuggestionsData.suggestedLegalEntities
                  )}
                </div>
              </>
            )}
            {!!stripeSuggestionsData?.otherLegalEntities.length && (
              <>
                <label className={classes.label}>Legal entities</label>
                <div className={classes.suggestions}>
                  {renderSuggestions(stripeSuggestionsData.otherLegalEntities)}
                </div>
              </>
            )}
          </RadioGroup>

          <Button
            onClick={() => setOpenConfirmModal(true)}
            block
            appearance="subtle"
          >
            I don’t use this Stripe account
          </Button>
          <Button
            disabled={!legalEntityId}
            className="mt-0"
            onClick={onLinkStripeAccount}
            appearance="primary"
            size="lg"
            block
          >
            Next
          </Button>
        </Modal.Body>
      </Modal>
    </>
  )
})

export default UpdateModal
