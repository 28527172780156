export enum AssetType {
  CURRENCY = 'CURRENCY',
  STOCK = 'STOCK',
  CRYPTO = 'CRYPTO',
}

export type Asset = {
  code: string
  icon?: string
  key: string
  name: string
  symbol: string
  type: AssetType
}

export enum Role {
  READ = 'READ',
  WRITE = 'WRITE',
  OWNER = 'OWNER',
}

export enum EntityPermissionStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

export enum EntityType {
  PERSONAL = 'PERSONAL',
  BUSINESS = 'BUSINESS',
}

export enum EntitySource {
  IM = 'IM',
  SF = 'SF',
}

export type EntityPermission = {
  actualEmail: string
  id: number
  inviteEmail: string
  role: Role
  ssoUserId: number
  status: EntityPermissionStatus
}

export type Entity = {
  id: number
  name: string
  permissions: EntityPermission[]
  primaryCurrency: Asset
  type: EntityType
  tmpSource: EntitySource
}
