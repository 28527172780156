import { ProductLine, Invoice, DOCUMENTTYPE, Address } from "./types";
import helpers from "../../utils/helpers";
import { v4 as uuidv4 } from "uuid";
import { DiscountType, DueType, SentState, TaxType } from "services/invoices-service/types";

export const initialProductLine: ProductLine = {
  itemName: "",
  itemDescription: "",
  qty: "1",
  itemTax: "",
  itemTaxAmount: "",
  itemTaxName: "",
  itemPrice: "",
  itemTotal: "",
  itemOrder: "",
  itemDiscountType: "Percentage",
  itemDiscountPercentage: "",
  itemDiscountAmount: "",
  itemSubTotal: "",
  isTaxable: true,
};

export const initialAddress: Address = {
  addressLine1: "",
  addressLine2: "",
  zip: "",
  countryIsoCode: "",
  country: "",
  city: "",
  state: "",
};

export const docNumberHandler = helpers?.incrementINumber;
// TODO: HERE ADD BASE64
export const initialInvoice: (id?: string) => Invoice = (id?: string) => {
  return {
    archived: false,
    base64: {
      _logo: "",
      _signature: "",
      _photos: [],
    },
    id: id || uuidv4(),
    logo: "",
    signature: "",
    subTotal: "",
    total: "",
    discountPercentage: "",
    discountType: DiscountType.PERCENTAGE,
    discountAmount: "",
    discountLabel: "Discount",
    documentType: "Invoice",
    companyName: "",
    companyId: "",
    companyPhone: "",
    companyEmail: "",
    companyWebsite: "",
    companyAddress: initialAddress,
    companyNotes: "",
    billTo: "Bill to",
    billFrom: "From",
    shipTo: "Ship to",
    clientId: "",
    clientName: "",
    clientBusinessName: "",
    clientMobile: "",
    clientPhone: "",
    clientEmail: "",
    shippingCostAmount: "",
    amountPaid: "",
    shippingCostLabel: "Shipping Cost",
    shippingAddress: initialAddress,
    shippingTrackNumber: "",
    clientBillingAddress: initialAddress,
    invoiceTitleLabel: "Invoice no.:",
    documentNumber: "",
    issueDateLabel: "Invoice date:",
    issueDate: "",
    dueDateLabel: "Due:",
    dueDate: "",
    productLineDescription: "DESCRIPTION",
    productLineQuantity: "QTY",
    productLineTax: "TAX",
    productLineDisc: "DISC",
    productLineQuantityRate: "RATE",
    productLineQuantityAmount: "AMOUNT",
    documentItems: [
      // {
      //   description: 'Brochure Design',
      //   quantity: '2',
      //   rate: '100.00',
      // },
      { ...initialProductLine },
    ],
    subTotalLabel: "Subtotal",
    taxLabel: "",
    taxRate: "",
    totalLabel: "Total",
    balanceLabel: "Balance Due",
    amountPaidLabel: "Amounts paid",
    currency: helpers.getCurrency(),
    paymentLabel: "Payment instruction",
    paymentInstructions: "",
    // payEmail: "",
    // payPayableTo: "",
    // payBankTransfer: "",
    // payOther: "",
    notesLabel: "Notes",
    notes: "",
    dimensions: {
      width: "",
      height: "",
    },
    photosLabel: "Photos",
    photos: [],

    photosDimensions: {},
    taxIncluded: true,
    taxType: TaxType.PER_ITEM,

    sentState: SentState.DRAFT,
    dueType: DueType.CUSTOM_DATE,
    clientNote: "",
    createdAt: "",
    terms: "",
    clientShippingAddress: initialAddress,
    payments: [],
    docHistory: [],
    companyTaxNumber: "",
    stripeLink: "",
    __type: DOCUMENTTYPE.INV,
  };
};
export const initialEstimate: (id?: string) => Invoice = (id?: string) => {
  return {
    archived: false,
    base64: {
      _logo: "",
      _signature: "",
      _photos: [],
    },
    id: id || uuidv4(),
    logo: "",
    signature: "",
    subTotal: "",
    total: "",
    discountPercentage: "",
    discountAmount: "",
    discountType: DiscountType.PERCENTAGE,
    discountLabel: "Discount",
    documentType: "Estimate",
    companyName: "",
    companyId: "",
    companyPhone: "",
    companyEmail: "",
    companyWebsite: "",
    companyAddress: initialAddress,
    companyNotes: "",
    billTo: "Bill to",
    billFrom: "From",
    shipTo: "Ship to",
    clientId: "",
    clientName: "",
    clientBusinessName: "",
    clientMobile: "",
    clientPhone: "",
    clientEmail: "",
    shippingCostAmount: "",
    amountPaid: "",
    shippingCostLabel: "Shipping Cost",
    shippingAddress: initialAddress,
    shippingTrackNumber: "",
    clientBillingAddress: initialAddress,
    invoiceTitleLabel: "Estimate no.:",
    documentNumber: "",
    issueDateLabel: "Estimate date:",
    issueDate: "",
    dueDateLabel: "Due:",
    dueDate: "",
    productLineDescription: "DESCRIPTION",
    productLineQuantity: "QTY",
    productLineTax: "TAX",
    productLineDisc: "DISC",
    productLineQuantityRate: "RATE",
    productLineQuantityAmount: "AMOUNT",
    documentItems: [
      // {
      //   description: 'Brochure Design',
      //   quantity: '2',
      //   rate: '100.00',
      // },
      { ...initialProductLine },
    ],
    subTotalLabel: "Subtotal",
    taxLabel: "",
    taxRate: "",
    totalLabel: "Total",
    balanceLabel: "Balance Due",
    amountPaidLabel: "Amounts paid",
    currency: helpers.getCurrency(),
    paymentLabel: "Payment instruction",
    paymentInstructions: "",
    // payEmail: "",
    // payPayableTo: "",
    // payBankTransfer: "",
    // payOther: "",
    notesLabel: "Notes",
    notes: "",
    dimensions: {
      width: "",
      height: "",
    },
    photosLabel: "Photos",
    photos: [],

    photosDimensions: {},
    taxIncluded: true,
    taxType: TaxType.PER_ITEM,

    sentState: SentState.DRAFT,
    dueType: DueType.CUSTOM_DATE,
    clientNote: "",
    terms: "",
    createdAt: "",
    clientShippingAddress: initialAddress,
    payments: [],
    docHistory: [],
    companyTaxNumber: "",
    stripeLink: "",
    __type: DOCUMENTTYPE.EST,
  };
};
