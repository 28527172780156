import React, { MutableRefObject, useState } from "react";

import { Button, Field, Modal, SchemaTyped, Form } from "@nalezhytyi/saldo-ui";
import { observer } from "mobx-react";
import invoicesStore from "../../store/mobx/InvoicesStore";
import { useAmplitude } from "react-amplitude-hooks";
import useNotification from "scripts/hooks/useNotification";

interface IShare {
  emailToSend?: string;
  estimateId?: string;
  invoiceId?: string;
  sendState?: string;
  onCancel: () => void;
}

const SendEmailModal = observer((props: IShare) => {
  const [isLoading, setIsLoading] = useState(false);
  const notification = useNotification()
  const { emailToSend, onCancel, estimateId, invoiceId, sendState } = props;

  const [formData, setFormData] = useState({ email: emailToSend });
  const formRef = React.useRef(null) as MutableRefObject<any>;

  const model = SchemaTyped.Model({
    email: SchemaTyped.Types.StringType()
      .isRequired("This field is required")
      .isEmail("Please enter a valid email address."),
  });

  const { logEvent } = useAmplitude();
  return (
    <Modal size="xs" open={true} backdrop>
      <Modal.Body>
        <Form formValue={formData} fluid model={model} ref={formRef}>
          <h3 className="mb-16">Send to</h3>
          <Field
            name="email"
            placeholder="Email"
            onChange={(value: string) =>
              setFormData({ email: value.trimStart() })
            }
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={"color-red"}
          disabled={isLoading}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          appearance={"primary"}
          loading={isLoading}
          disabled={isLoading}
          onClick={async () => {
            if (formRef.current.check()) {
              setIsLoading(true);
              notification.push("Sending, please wait.", {
                type: "info",
              });
              await invoicesStore
                .sendDocument(
                  formData.email || "",
                  invoiceId || "",
                  estimateId || "",
                  () => {
                    // logEvent("invoice_sendInvoice_clicked", {
                    //   Source: "All Docs Sidebar",
                    // });
                  },
                  () => {
                    logEvent("invoice_sendInvoice_success", {
                      Source: sendState,
                    });
                    notification.push(
                      "Document successfully sent to client's email",
                      {
                        type: "info",
                      }
                    );
                  }
                )
                .finally(() => {
                  invoicesStore.sendToEmailDataClear();
                  // finallyCallback();
                });
              setIsLoading(false);
            }
          }}
        >
          {isLoading ? "Loading..." : "Send"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export const SendEmailModalHandler = observer(() => {
  const { emailToSend, isOpen, invoiceId, estimateId, sendState } =
    invoicesStore.sendToEmailData;

  return (
    (isOpen && (
      <SendEmailModal
        emailToSend={emailToSend}
        invoiceId={invoiceId}
        estimateId={estimateId}
        sendState={sendState}
        onCancel={() => {
          invoicesStore.sendToEmailDataClear();
        }}
      />
    )) || <></>
  );
});
