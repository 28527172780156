import { API } from 'services/http.service'
import { IFilterModel, ILink, IShareModel } from './types'
import { Invoice } from 'data/documents/types'

class InvoicesService {
  async getInvoices(params: IFilterModel) {
    const { data } = await API.get<{
      offset: number
      total: number
      results: Invoice[]
    }>('/v5/{accountEntityId}/invoices', {
      params,
    })
    return data
  }

  async getEstimates(params: IFilterModel) {
    const { data } = await API.get<{
      offset: number
      total: number
      results: Invoice[]
    }>('/v5/{accountEntityId}/estimates', {
      params,
    })
    return data
  }

  async getInvoice(id: string) {
    const { data } = await API.get<Invoice>(
      `/v5/{accountEntityId}/invoices/${id}`
    )
    return data
  }

  async getEstimate(id: string) {
    const { data } = await API.get<Invoice>(
      `/v5/{accountEntityId}/estimates/${id}`
    )
    return data
  }

  async postInvoice(payload: Invoice | any) {
    const { data } = await API.post('/v5/{accountEntityId}/invoices', {
      data: payload,
    })
    return data
  }

  async postEstimate(payload: Invoice | any) {
    const { data } = await API.post('/v5/{accountEntityId}/estimates', {
      data: payload,
    })
    return data
  }

  async getAmount() {
    const { data } = await API.get<number>(
      'v5/{accountEntityId}/amount-documents'
    )
    return data
  }

  async shareLink(data: IShareModel) {
    const {
      data: { link },
    } = await API.post<ILink>('/v5/share/link', { data })
    return link
  }

  async shareEmail(data: IShareModel) {
    const {
      data: { link },
    } = await API.post<ILink>('/v5/share/mail', { data })
    return link
  }
}

export default new InvoicesService()
