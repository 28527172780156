import { API } from 'services/http.service'
import {
  LegalEntityStripeAction,
  PaypalIntegration,
  StripeAccountResponse,
  AccountUpdateData,
} from './types'

class PaymentService {
  async getStripeAccount(legalEntityId: string) {
    const { data } = await API.get<StripeAccountResponse>(
      `/v5/legal-entities/${legalEntityId}/stripe-accounts`
    )
    return data
  }

  async updateStripeAccount(
    legalEntityId: string,
    data: AccountUpdateData
  ) {
    return await API.put(
      `/v5/legal-entities/${legalEntityId}/stripe-accounts`,
      { data }
    )
  }

  async connectStripe(legalEntityId: string) {
    const {
      data: { link },
    } = await API.post<{ link: string }>(
      `/v5/legal-entities/${legalEntityId}/stripe-accounts`,
      {
        headers: { source: 'WEB' },
      }
    )
    return link
  }

  async disconnectGlobalStripe(customerId: string) {
    return await API.put(`/v1/stripe/account/${customerId}/disconnect`)
  }

  async updateStripe(legalEntityId: string, action: LegalEntityStripeAction) {
    return await API.patch(
      `/v5/legal-entities/${legalEntityId}/stripe-accounts`,
      { data: { type: action } }
    )
  }

  async updatePaypalAccounts(
    id: string,
    data: {
      paymentLinkEnabled: boolean
      passFeesToClients: boolean
    }
  ) {
    await API.put(`/v5/legal-entities/${id}/paypal/accounts`, {
      data,
    })
  }

  async conectPaypal(id: string) {
    const {
      data: { link },
    } = await API.post<{ link: string }>(
      `/v5/legal-entities/${id}/paypal/accounts/onboard`,
      {
        data: {
          type: 'BRANDED_ONLY',
          returnUrl: `${process.env.REACT_APP_WEB_HOST}legal_entities`,
        },
      }
    )
    return link
  }

  async getPaypalIntegration(id: string) {
    try {
      const { data } = await API.patch<PaypalIntegration>(
        `/v5/legal-entities/${id}/paypal/accounts/actualize`
      )
      return data
    } catch (error) {
      return null
    }
  }
}

export default new PaymentService()
