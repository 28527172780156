import React from "react";
import { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import deviceStore, {
  BREAKPOINTS,
  DEVICES,
} from "../../store/mobx/DeviceStore";

export const DeviceController = observer(() => {
  const device = deviceStore.device;
  // const isBroken = deviceStore.isBroken;
  // const isCollapsed = deviceStore.collapsed;

  const breakValuesArr: number[] = Object.values(BREAKPOINTS).map((el) => +el);
  const breakKeysArr: string[] = Object.keys(DEVICES);

  const onResize = useCallback(() => {
    // if (isCollapsed && window.innerWidth > 1440) {
    //   deviceStore.setCollapsed(false);
    // }

    for (let i = breakKeysArr.length; i--; i > 0) {
      if (window.innerWidth >= breakValuesArr[i]) {
        if (device !== breakKeysArr[i]) {
          // @ts-ignore
          deviceStore.setDevice(breakKeysArr[i]);
        }
        break;
      }
    }
  }, [breakKeysArr, breakValuesArr, device]);

  // useEffect(() => {
  //   if (
  //     device &&
  //     (device === DEVICES.MOBILE ||
  //       device === DEVICES.TABLET_PORT ||
  //       device === DEVICES.TABLET_LAND) &&
  //     !isBroken
  //   ) {
  //     deviceStore.setIsBroken(true);
  //   } else if (device && device === DEVICES.DESKTOP && isBroken) {
  //     deviceStore.setIsBroken(false);
  //   }
  // }, [device, isBroken]);

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  return <></>;
});
