import React, { MutableRefObject, useEffect, useState } from 'react'
import GoogleAuth from '../GoogleAuth'
import AppleAuth from '../AppleAuth'
import { observer } from 'mobx-react'
import authStore from 'store/mobx/AuthStore'
import localeKeys from 'locale'
import { LogOnMount, useAmplitude } from 'react-amplitude-hooks'
import Countdown, { zeroPad } from 'react-countdown'
import { Helmet } from 'react-helmet'
import { AuthSteps } from 'data/global/types'
import ReactCodeInput from 'react-code-input'
import {
  Button,
  Checkbox,
  Divider,
  Field,
  Form,
  Loader,
  SchemaTyped,
} from '@nalezhytyi/saldo-ui'

import classes from './classes.module.scss'
import dayjs from 'dayjs'
import { DiscountType } from 'services/invoices-service/types'
import helpers from 'utils/helpers'
import invoicesStore from 'store/mobx/InvoicesStore'

enum AmplSignInSources {
  Email = 'email',
  Google = 'google',
  Apple = 'apple',
}

const CODE_INPUTS_COUNT = 6

export const SignUpFullScreen = observer(() => {
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [checked, setChecked] = useState(false)
  const [step, setStep] = useState(AuthSteps.STEP_WELCOME)
  const [isResendBtn, setIsResendBtn] = useState(false)
  const { generatorInvoice: invoice } = invoicesStore
  const { documentCompany, documentClient } = invoice || {}

  const companyAddress = Object.values(documentCompany?.address || {})
    .filter((s: any) => s.trim())
    .join(', ')

  const clientAddress = Object.values(documentClient?.billingAddress || {})
    .filter((s: any) => s.trim())
    .join(', ')

  const formRef = React.useRef(null) as MutableRefObject<any>

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'signUp_popup_shown' })
    console.log('signUp_popup_shown');
    window.parent.postMessage({ method: 'iframeInitialized' }, '*')

    const handleMessage = (event: MessageEvent) => {
      if (!event.origin.includes('saldoinvoice.com')) {
        return
      }

      const { method, data } = event.data

      if (method === 'jsonInvoiceData') {
        invoicesStore.setGeneratorInvoice(data)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  useEffect(() => {
    if (authStore.user?.email && invoice) {
      window.parent.postMessage({ method: 'iframeSignedUp' }, '*')
    }
  }, [authStore.user?.email, invoice])

  const model = SchemaTyped.Model({
    email: SchemaTyped.Types.StringType()
      .isRequired('This field is required')
      .isEmail('Please enter a valid email address.'),
    checkbox: SchemaTyped.Types.BooleanType()
      .isRequired('This field is required')
      .addRule((value: any) => {
        return !value
      }, 'This field is required'),
  })

  const { logEvent } = useAmplitude()
  const handleSubmit = async () => {
    if (step === AuthSteps.STEP_WELCOME) {
      if (!formRef.current?.check()) return

      await authStore.sendEmail(email)
      nextStep()
    }
  }

  const showResendBtn = () => {
    setIsResendBtn(true)
  }

  const nextStep = () => setStep(AuthSteps.STEP_REQUEST_CODE)

  const resendCode = () => {
    authStore.sendEmail(email)

    setIsResendBtn(false)
  }

  const handleSignInCLicked = (option: AmplSignInSources) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'signUp_popup_option_chosen', option })
    logEvent('signUp_popup_option_chosen', {
      option,
    })
    console.log('signUp_popup_option_chosen', option)
  }

  const handleAuthSuccess = (option: AmplSignInSources, isNewUser: boolean) => {
    const eventType = isNewUser ? 'registration_signUp_success' : 'registration_signIn_success';
    console.log(eventType)
  
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: eventType, option });
    
    logEvent(eventType, { option });
  }

  const renderSignIn = () => {
    let content

    switch (step) {
      case AuthSteps.STEP_REQUEST_CODE:
        content = (
          <div>
            <Form fluid ref={formRef}>
              <div>
                <h2 className="mb-48">Sign in now! It’s absolutely free!</h2>
                <div className={classes.subtitle}>
                  Please check your {email} inbox and enter the security code
                  from the email.
                </div>
                <ReactCodeInput
                  autoFocus
                  value={code}
                  type="number"
                  fields={CODE_INPUTS_COUNT}
                  onChange={(code) => setCode(code)}
                  inputMode="numeric"
                  name="code"
                  className="code-input mb-16"
                />

                {isResendBtn && (
                  <div className={classes.subheadline}>
                    <span>Didn’t get the email? &nbsp;</span>
                    <button
                      className={classes.resendButton}
                      disabled={!isResendBtn}
                      onClick={resendCode}
                    >
                      Resend Code
                    </button>
                  </div>
                )}
                {!isResendBtn && (
                  <div className={classes.subheadline}>
                    <span>
                      Didn’t get the email? You’ll be able to resend it in
                      &nbsp;
                    </span>
                    <span className={classes.counter}>
                      <Countdown
                        zeroPadTime={2}
                        date={Date.now() + 15000}
                        renderer={({ minutes, seconds }) => (
                          <span>
                            {zeroPad(minutes)}:{zeroPad(seconds)}
                          </span>
                        )}
                        onComplete={showResendBtn}
                      />
                    </span>
                  </div>
                )}
                <Button
                  className="mt-24"
                  block
                  appearance="primary"
                  onClick={async () => {
                    if (
                      code.length !== CODE_INPUTS_COUNT ||
                      !formRef.current.check()
                    )
                      return
                    handleSignInCLicked(AmplSignInSources.Email)
                    await authStore.sendCode(code, async (isNewUser) => {
                      handleAuthSuccess(AmplSignInSources.Email, isNewUser)
                    })
                  }}
                >
                  Continue
                </Button>
              </div>
              {authStore.isLoading && <Loader backdrop center />}
            </Form>
          </div>
        )
        break
      default:
        content = (
          <div>
            <Form fluid model={model} ref={formRef} checkTrigger="blur">
              <div>
                <h2>
                  Sign up to <span>download</span> your Invoice
                </h2>
                <div className={classes.subtitle}>
                  Issue invoices, track statuses, receive payments and much
                  more!
                </div>
                <div className="df aic gap-8">
                  <GoogleAuth
                    iconButton
                    onClickHandler={() => {
                      handleSignInCLicked(AmplSignInSources.Google)
                    }}
                    onSuccessHandler={(isNewUser) => {
                      handleAuthSuccess(AmplSignInSources.Google, isNewUser)
                    }}
                  />
                  <AppleAuth
                    iconButton
                    onClickHandler={() => {
                      handleSignInCLicked(AmplSignInSources.Apple)
                    }}
                    onSuccessHandler={(isNewUser) => {
                      if(isNewUser) {}
                      handleAuthSuccess(AmplSignInSources.Apple, isNewUser)
                    }}
                  />
                </div>
                <Divider
                  style={{
                    maxWidth: '140px',
                    marginInline: 'auto',
                    marginBlock: '24px',
                  }}
                >
                  OR
                </Divider>
                <Field
                  onChange={(value: string) => setEmail(value)}
                  name="email"
                  value={email}
                  placeholder="Email"
                  style={{ marginBottom: '-10px' }}
                />
                <Form.Group className="inlineFormControl">
                  <Form.Control
                    checkTrigger="change"
                    name="checkbox"
                    className={'w-unset'}
                    accepter={Checkbox}
                    onChange={(_: any, checked: boolean) => setChecked(checked)}
                    checked={checked}
                    value={checked}
                  />
                  <div className={classes.subheadline}>
                    By signing in, you agree to our{' '}
                    <a
                      href="https://telegra.ph/Terms-of-Use-11-08"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms
                    </a>{' '}
                    &{' '}
                    <a
                      href="https://telegra.ph/Privacy-Policy-11-08-8"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy&nbsp;Policy
                    </a>
                  </div>
                </Form.Group>
                <Button
                  type="submit"
                  appearance="primary"
                  block
                  onClick={handleSubmit}
                >
                  {localeKeys.main_Continue}
                </Button>
              </div>
              {authStore.isLoading && <Loader backdrop center />}
            </Form>
          </div>
        )
        break
    }
    return content
  }

  if (!invoice) return <Loader backdrop center size="lg" />

  return (
    <>
      <LogOnMount eventType="signUp_popup_shown" />
      <Helmet>
        <title>Sign In - Invoice Maker: login to your account</title>
        <meta
          name="description"
          content="Login to Your Account right now. Don't have an Invoice Maker account yet? No worries, joining is easy."
        />
      </Helmet>
      <div className={classes.wrapper}>
        <div className={classes.generatorColumn}>
          <form>
            <h2>Your Invoice is ready!</h2>
            <div className={classes.generator}>
              <div
                className={`${classes.genContainer} df space-between mb-8 gap-16`}
              >
                {documentCompany?.logo ? (
                  <img
                    src={documentCompany.logo}
                    className={classes.invoiceLogo}
                    alt="Company Logo"
                  />
                ) : null}
                <div className="text-right">
                  <div className={classes.invoiceTitle}>Invoice</div>
                  <div className={classes.invoiceInfo}>
                    <div>
                      <div>Invoice no.:</div>
                      <div>Invoice date:</div>
                      <div>Due:</div>
                    </div>
                    <div>
                      <div>{invoice.number}</div>
                      <div>
                        {dayjs(invoice.issueDate).format('MMM DD, YYYY')}
                      </div>
                      <div>{dayjs(invoice.dueDate).format('MMM DD, YYYY')}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${classes.genContainer} df space-between mb-16 gap-16`}
              >
                <div className={classes.invoiceFromTo}>
                  <div>From</div>
                  {documentCompany?.businessName && (
                    <div>{documentCompany?.businessName}</div>
                  )}
                  {documentCompany?.email && (
                    <div>{documentCompany?.email}</div>
                  )}
                  {documentCompany?.mobilePhone && (
                    <div>{documentCompany?.mobilePhone}</div>
                  )}
                  {documentCompany?.phone && (
                    <div>{documentCompany?.phone}</div>
                  )}
                  {documentCompany?.website && (
                    <div>{documentCompany?.website}</div>
                  )}
                  {companyAddress && <div>{companyAddress}</div>}
                </div>
                <div className={`${classes.invoiceFromTo} text-right`}>
                  <div>Bill to</div>
                  {documentClient?.name && <div>{documentClient?.name}</div>}
                  {documentClient?.email && <div>{documentClient?.email}</div>}
                  {documentClient?.mobilePhone && (
                    <div>{documentClient?.mobilePhone}</div>
                  )}
                  {documentClient?.phone && <div>{documentClient?.phone}</div>}
                  {clientAddress && <div>{clientAddress}</div>}
                </div>
              </div>
              <table className={classes.invoiceItems}>
                <thead>
                  <tr>
                    <td>DESCRIPTION</td>
                    <td>RATE, {invoice?.currency?.code}</td>
                    <td>QTY</td>
                    <td>TAX, %</td>
                    <td>DISC, %</td>
                    <td>AMOUNT, {invoice?.currency?.code}</td>
                  </tr>
                </thead>
                <tbody>
                  {invoice?.documentItems.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <p>{item.itemName}</p>
                        {item.itemDescription && (
                          <p className={classes.itemDescription}>
                            {item.itemDescription}
                          </p>
                        )}
                      </td>
                      <td>{helpers.getAmount(item.price)}</td>
                      <td>{item.qty}</td>
                      <td>
                        {item.taxAmount
                          ? helpers.getAmount(item.taxAmount) + '%'
                          : '-'}
                      </td>
                      <td>
                        {item.discount
                          ? helpers.getAmount(item.discount) + '%'
                          : '-'}
                      </td>
                      <td>{helpers.getAmount(item.total)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div
                className={`${classes.genContainer} df space-between mb-8 gap-16`}
              >
                <div className={classes.paymentInstruction}>
                  {invoice.paymentInstructions && (
                    <>
                      <div className="semi-bold">Payment Instruction</div>
                      <div className="instructions">
                        {invoice.paymentInstructions}
                      </div>
                    </>
                  )}
                </div>
                <div className="text-right">
                  <ul className="sum-list">
                    {invoice.subtotal && (
                      <li className="space-between semi-bold">
                        <span>Subtotal:</span>
                        <span>
                          {invoice.currency.code}{' '}
                          {helpers.getAmount(invoice.subtotal)}
                        </span>
                      </li>
                    )}

                    {invoice.discount && (
                      <li className="space-between">
                        <span>
                          Discount
                          {invoice.discount.type === DiscountType.PERCENTAGE &&
                            ` (${invoice.discount.percentage}%)`}
                          :
                        </span>
                        <span className={classes.discountAmount}>
                          {invoice.currency.code}{' '}
                          {helpers.getAmount(invoice.discount.amount)}
                        </span>
                      </li>
                    )}

                    {invoice.shippingAmount && (
                      <li className="space-between">
                        <span>Shipping Cost:</span>
                        <span>
                          {invoice.currency.code}{' '}
                          {helpers.getAmount(invoice.shippingAmount)}
                        </span>
                      </li>
                    )}

                    {invoice.taxes && (
                      <li className="space-between">
                        <span>{invoice.taxes[0].name || 'Tax'}:</span>
                        <span>
                          {invoice.currency.code}{' '}
                          {helpers.getAmount(invoice.taxes[0].rate)}
                        </span>
                      </li>
                    )}
                  </ul>

                  {invoice.total && (
                    <div className={`${classes.balanceDue} space-between`}>
                      <span className="semi-bold gap-8">Balance Due:</span>
                      <span className="semi-bold">
                        {invoice.currency.code}{' '}
                        {helpers.getAmount(invoice.total)}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className={classes.authColumn}>{renderSignIn()}</div>
        {authStore.user?.email && <Loader backdrop center size="lg" />}
      </div>
    </>
  )
})

export default SignUpFullScreen
