export enum METHODTYPE {
  Bank_account = 'BANK_ACCOUNT',
  Card = 'CARD',
  Cash = 'CASH',
  Check = 'CHECK',
  Pay_pal = 'PAY_PAL',
  Stripe = 'STRIPE',
  Other = 'OTHER',
}

export enum LegalEntityStripeAction {
  DISCONNECT = 'DISCONNECT',
  CONNECTION_CONFIRMATION = 'CONNECTION_CONFIRMATION',
  LINK_EXISTING_STRIPE_TO_LEGAL_ENTITY = 'LINK_EXISTING_STRIPE_TO_LEGAL_ENTITY',
}

export interface IPaymentsRectData {
  paymentInstructions?: string
  PAY_PAL?: string
  BANK_ACCOUNT?: string
  CHECK?: string
  OTHER?: string
}
export type StripeAccountResponse = {
  legalEntityId: string
  accountId: string
  onboardingCompleted: boolean
  paymentsEnabled: boolean
  paymentLinkEnabled: boolean
  passFeesToClients: boolean
}

export type AccountUpdateData = {
  paymentLinkEnabled: boolean
  passFeesToClients: boolean
}

export type PaypalIntegration = {
  legalEntityId: string
  merchantId: string
  onboardingCompleted: boolean
  paymentsEnabled: boolean
  paymentLinkEnabled: boolean
  passFeesToClients: boolean
}

export type Integration = 'paypal' | 'stripe'
