export interface IFilterModel {
  currency?: string
  dateRange?: string | null
  dateFrom?: string | null
  dateTo?: string | null

  limit?: number
  type?: 'Waiting_for_payment' | 'Overdue' | 'With_payment' | null
  sortBy?: string
  offset?: number
  search?: string
  sortType?: 'DESC' | 'ASC'
}

export enum DueType {
  NONE = 'NONE',
  ON_RECEIPT = 'ON_RECEIPT',
  NEXT_DAY = 'NEXT_DAY',
  DAYS_7 = 'DAYS_7',
  DAYS_30 = 'DAYS_30',
  CUSTOM_DATE = 'CUSTOM_DATE',
}

export enum DiscountType {
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}

export enum PAIDSTATE {
  STATUS_PARTIALLY_PAID = 'STATUS_PARTIALLY_PAID',
  STATUS_PAID = 'STATUS_PAID',
  STATUS_UNPAID = 'STATUS_UNPAID',
  STATUS_OVERPAID = 'STATUS_OVERPAID',
}

export enum SentState {
  DRAFT = 'DRAFT',
  COMPLETED = 'COMPLETED',
  PRINTED = 'PRINTED',
  SENT = 'SENT',
  VIEWED = 'VIEWED',
}

export enum TaxType {
  PER_ITEM = 'PER_ITEM',
  ON_TOTAL = 'ON_TOTAL',
  NONE = 'NONE',
}
export interface IShareModel {
  invoiceId?: string
  estimateId?: string
  recipient?: string
}

export interface ILink {
  link: string
}

type Address = {
  addressLine1: string
  addressLine2: string
  postalCode: string
  country: string
  city: string
  state: string
}

type Tax = {
  name: string | null
  rate: number
}

type DocumentItem = {
  itemName: string
  itemDescription?: string
  qty: number
  total: number
  subtotal: number
  price: number
  order: number
  discount: number | null
  tax: Tax
  isTaxable: boolean
  taxAmount: number | null
}

type Discount = {
  type: string
  amount: number
  percentage: number
}

type Currency = {
  code: string
  symbol: string
}

type DocumentClient = {
  name: string
  email: string | null
  billingAddress: Address
  mobilePhone: string | null
  phone: string | null
  clientId: string
  note: string | null
  shippingAddress: Address
}

type DocumentCompany = {
  businessName: string
  email: string | null
  taxNumber: string | null
  terms: string | null
  mobilePhone: string | null
  phone: string | null
  website: string | null
  logo: string
  signature: string | null
  ownerName: string | null
  address: Address
  companyId: string
}

export type GeneratorInvoice = {
  number: string
  documentType: string
  issueDate: number
  dueDate: number
  dueType: string
  documentClient: DocumentClient
  documentCompany: DocumentCompany
  total: number
  subtotal: number
  documentItems: DocumentItem[]
  taxes: Tax[]
  shippingAmount: number
  discount: Discount
  currency: Currency
  paymentInstructions: string
  taxType: string
  taxIncluded: boolean
}