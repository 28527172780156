import { API } from 'services/http.service'
import { Entity, EntitySource } from './types'
import LocalStorageManager from 'manager/local-storage.manager'

const ENDPOINT = 'v6/accounting-entities'

class AccountingService {
  async getAccountingEntity() {
    const { data } = await API.get<Entity[]>(ENDPOINT)
    const imEntity = data.find((entity) => entity.tmpSource === EntitySource.IM)
    return imEntity
  }

  async createAccountingEntity() {
    const { data } = await API.post<Entity>(ENDPOINT, {
      data: {
        name: 'Invoice Maker Entity',
        primaryCurrency: 'USD',
        type: 'BUSINESS',
        tmpSource: 'IM',
      },
    })

    return data
  }

  async invokeAccountingEntity() {
    try {
      const invoiceMakerEntity = await this.getAccountingEntity()

      if (!invoiceMakerEntity) {
        const createdEntity = await this.createAccountingEntity()
        LocalStorageManager.set('accountEntityId', createdEntity.id)

        return createdEntity
      }

      LocalStorageManager.set('accountEntityId', invoiceMakerEntity.id)

      return invoiceMakerEntity
    } catch (error) {
      console.error('Error in invoke:', error)
    }
  }
}

export default new AccountingService()
