const PREFIX = 'INVOICE_MAKER_APP_'

const LocalStorageManager = {
  has: (key: string) => {
    const item = window.localStorage.getItem(PREFIX + key)
    return item !== null && item !== undefined
  },
  get: <T = string>(key: string) => {
    const string = window.localStorage.getItem(PREFIX + key) as string
    try {
      return JSON.parse(string) as T
    } catch (error) {
      return null
    }
  },
  delete: (key: string) => {
    window.localStorage.removeItem(PREFIX + key)
  },
  set: (key: string, object: unknown) => {
    window.localStorage.setItem(PREFIX + key, JSON.stringify(object))
  },
  getAll: (keys: string[]) => {
    const result: Record<string, any> = {}

    keys.forEach((key) => {
      const value = LocalStorageManager.get(key)
      if (value !== null) {
        result[key] = value
      }
    })

    return result
  },
  clear: (exceptionKeys: string[] = []) => {
    Object.keys(window.localStorage).forEach((key) => {
      if (
        key.startsWith(PREFIX) &&
        !exceptionKeys.includes(key.replace(PREFIX, ''))
      ) {
        window.localStorage.removeItem(key)
      }
    })
  },
}

export default LocalStorageManager
