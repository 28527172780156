const routes = {
  home: "/",
  auth: "/auth",
  signIn: "/auth/sign-in",
  signUp: "/auth/sign-up",
  signUpFullScreen: "/auth/sign-up-full-screen",
  allDocuments: "/all-documents/:type?",
  generalSettings: "/settings/general_settings",
};

export default routes;
