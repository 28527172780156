export interface ICURRENCYVAL {
  code: string;
  symbol: string;
  currency?: string;
  flagUrl?: string;
}
export interface ICURRENCY {
  [key: string]: ICURRENCYVAL;
}

export enum PAYTYPES {
  Visa = "Visa",
  Americanexpress = "American Express",
  Discover = "Discover",
  Mastercard = "MasterCard",
}

export const CURRENCIES: ICURRENCY = {
  USD: {
    code: "USD",
    symbol: "$",
    currency: "United States Dollar",
  },
  EUR: {
    code: "EUR",
    symbol: "€",
    currency: "Euro",
  },
  CAD: {
    code: "CAD",
    symbol: "$",
    currency: "Canadian Dollar",
  },
  GBP: {
    code: "GBP",
    symbol: "£",
    currency: "British Pound",
  },
  RUB: {
    code: "RUB",
    symbol: "₽",
    currency: "Russian Ruble",
  },
  UAH: {
    code: "UAH",
    symbol: "₴",
    currency: "Ukraine Hryvnia",
  },
  BYN: {
    code: "BYN",
    symbol: "Br",
    currency: "Belarusian Ruble",
  },
  AED: {
    code: "AED",
    symbol: "د.إ",
    currency: "Emirati Dirham",
  },
  AFN: {
    code: "AFN",
    symbol: "؋",
    currency: "Afghanistan Afghani",
  },
  ALL: {
    code: "ALL",
    symbol: "L",
    currency: "Lek",
  },
  AMD: {
    code: "AMD",
    symbol: "֏",
    currency: "Armenian Dram",
  },
  ANG: {
    code: "ANG",
    symbol: "ƒ",
    currency: "Netherlands Antillean Guilder",
  },
  AOA: {
    code: "AOA",
    symbol: "Kz",
    currency: "Kwanza",
  },
  ARS: {
    code: "ARS",
    symbol: "$",
    currency: "Argentine Peso",
  },
  AUD: {
    code: "AUD",
    symbol: "$",
    currency: "Australian Dollar",
  },
  AWG: {
    code: "AWG",
    symbol: "ƒ",
    currency: "Aruban Florin",
  },
  AZN: {
    code: "AZN",
    symbol: "₼",
    currency: "Azerbaijan Manat",
  },
  BAM: {
    code: "BAM",
    symbol: "KM",
    currency: "Convertible Mark",
  },
  BBD: {
    code: "BBD",
    symbol: "$",
    currency: "Barbados Dollar",
  },
  BDT: {
    code: "BDT",
    symbol: "৳",
    currency: "Bangladeshi Taka",
  },
  BGN: {
    code: "BGN",
    symbol: "лв",
    currency: "Bulgarian Lev",
  },
  BHD: {
    code: "BHD",
    symbol: ".د.ب",
    currency: "Bahraini Dinar",
  },
  BIF: {
    code: "BIF",
    symbol: "FBu",
    currency: "Burundi Franc",
  },
  BMD: {
    code: "BMD",
    symbol: "$",
    currency: "Bermudian Dollar",
  },
  BND: {
    code: "BND",
    symbol: "$",
    currency: "Brunei Darussalam Dollar",
  },
  BOB: {
    code: "BOB",
    symbol: "$b",
    currency: "Bolivia Bolíviano",
  },
  BOV: {
    code: "BOV",
    symbol: "BOV",
    currency: "Mvdol",
  },
  BRL: {
    code: "BRL",
    symbol: "R$",
    currency: "Brazilian Real",
  },
  BSD: {
    code: "BSD",
    symbol: "$",
    currency: "Bahamian Dollar",
  },
  BTN: {
    code: "BTN",
    symbol: "Nu.",
    currency: "Bhutanese Ngultrum",
  },
  BWP: {
    code: "BWP",
    symbol: "P",
    currency: "Pula",
  },
  BZD: {
    code: "BZD",
    symbol: "BZ$",
    currency: "Belize Dollar",
  },
  CDF: {
    code: "CDF",
    symbol: "FC",
    currency: "Congolese Franc",
  },
  CHE: {
    code: "CHE",
    symbol: "CHE",
    currency: "WIR Euro",
  },
  CHF: {
    code: "CHF",
    symbol: "CHF",
    currency: "Switzerland Franc",
  },
  CHW: {
    code: "CHW",
    symbol: "CHW",
    currency: "WIR Franc",
  },
  CLF: {
    code: "CLF",
    symbol: "CLF",
    currency: "Unidad de Fomento",
  },
  CLP: {
    code: "CLP",
    symbol: "$",
    currency: "Chilean Peso",
  },
  CNY: {
    code: "CNY",
    symbol: "¥",
    currency: "China Yuan Renminbi",
  },
  COP: {
    code: "COP",
    symbol: "$",
    currency: "Colombian Peso",
  },
  COU: {
    code: "COU",
    symbol: "COU",
    currency: "Unidad de Valor Real",
  },
  CRC: {
    code: "CRC",
    symbol: "₡",
    currency: "Costa Rican Colon",
  },
  CUC: {
    code: "CUC",
    symbol: "$",
    currency: "Peso Convertible",
  },
  CUP: {
    code: "CUP",
    symbol: "₱",
    currency: "Cuban Peso",
  },
  CVE: {
    code: "CVE",
    symbol: "$",
    currency: "Cabo Verde Escudo",
  },
  CZK: {
    code: "CZK",
    symbol: "Kč",
    currency: "Czech Koruna",
  },
  DJF: {
    code: "DJF",
    symbol: "Fdj",
    currency: "Djibouti Franc",
  },
  DKK: {
    code: "DKK",
    symbol: "kr",
    currency: "Danish Krone",
  },
  DOP: {
    code: "DOP",
    symbol: "RD$",
    currency: "Dominican Peso",
  },
  DZD: {
    code: "DZD",
    symbol: "دج",
    currency: "Algerian Dinar",
  },
  EGP: {
    code: "EGP",
    symbol: "£",
    currency: "Egyptian Pound",
  },
  ERN: {
    code: "ERN",
    symbol: "Nfk",
    currency: "Nakfa",
  },
  ETB: {
    code: "ETB",
    symbol: "Br",
    currency: "Ethiopian Birr",
  },
  FJD: {
    code: "FJD",
    symbol: "$",
    currency: "Fiji Dollar",
  },
  FKP: {
    code: "FKP",
    symbol: "£",
    currency: "Falkland Islands Pound",
  },
  GEL: {
    code: "GEL",
    symbol: "₾",
    currency: "Georgian Lari",
  },
  GHS: {
    code: "GHS",
    symbol: "GH₵",
    currency: "Ghana Cedi",
  },
  GIP: {
    code: "GIP",
    symbol: "£",
    currency: "Gibraltar Pound",
  },
  GMD: {
    code: "GMD",
    symbol: "D",
    currency: "Gambian Dalasi",
  },
  GNF: {
    code: "GNF",
    symbol: "FG",
    currency: "Guinean Franc",
  },
  GTQ: {
    code: "GTQ",
    symbol: "Q",
    currency: "Quetzal",
  },
  GYD: {
    code: "GYD",
    symbol: "$",
    currency: "Guyana Dollar",
  },
  HKD: {
    code: "HKD",
    symbol: "$",
    currency: "Hong Kong Dollar",
  },
  HNL: {
    code: "HNL",
    symbol: "L",
    currency: "Lempira",
  },
  HRK: {
    code: "HRK",
    symbol: "kn",
    currency: "Croatia Kuna",
  },
  HTG: {
    code: "HTG",
    symbol: "G",
    currency: "Gourde",
  },
  HUF: {
    code: "HUF",
    symbol: "Ft",
    currency: "Hungary Forint",
  },
  IDR: {
    code: "IDR",
    symbol: "Rp",
    currency: "Indonesia Rupiah",
  },
  ILS: {
    code: "ILS",
    symbol: "₪",
    currency: "New Israeli Sheqel",
  },
  INR: {
    code: "INR",
    symbol: "₹",
    currency: "Indian Rupee",
  },
  IQD: {
    code: "IQD",
    symbol: "ع.د",
    currency: "Iraqi Dinar",
  },
  IRR: {
    code: "IRR",
    symbol: "﷼",
    currency: "Iranian Rial",
  },
  ISK: {
    code: "ISK",
    symbol: "kr",
    currency: "Iceland Krona",
  },
  JMD: {
    code: "JMD",
    symbol: "J$",
    currency: "Jamaican Dollar",
  },
  JOD: {
    code: "JOD",
    symbol: "JD",
    currency: "Jordanian Dinar",
  },
  JPY: {
    code: "JPY",
    symbol: "¥",
    currency: "Japanese Yen",
  },
  KES: {
    code: "KES",
    symbol: "KSh",
    currency: "Kenyan Shilling",
  },
  KGS: {
    code: "KGS",
    symbol: "лв",
    currency: "Som",
  },
  KHR: {
    code: "KHR",
    symbol: "៛",
    currency: "Riel",
  },
  KMF: {
    code: "KMF",
    symbol: "CF",
    currency: "Comorian Franc ",
  },
  KPW: {
    code: "KPW",
    symbol: "₩",
    currency: "North Korean Won",
  },
  KRW: {
    code: "KRW",
    symbol: "₩",
    currency: "Korea (South) Won",
  },
  KWD: {
    code: "KWD",
    symbol: "KD",
    currency: "Kuwaiti Dinar",
  },
  KYD: {
    code: "KYD",
    symbol: "$",
    currency: "Cayman Islands Dollar",
  },
  KZT: {
    code: "KZT",
    symbol: "₸",
    currency: "Kazakhstan Tenge",
  },
  LAK: {
    code: "LAK",
    symbol: "₭",
    currency: "Laos Kip",
  },
  LBP: {
    code: "LBP",
    symbol: "£",
    currency: "Lebanese Pound",
  },
  LKR: {
    code: "LKR",
    symbol: "₨",
    currency: "Sri Lanka Rupee",
  },
  LRD: {
    code: "LRD",
    symbol: "$",
    currency: "Liberian Dollar",
  },
  LSL: {
    code: "LSL",
    symbol: "M",
    currency: "Loti",
  },
  LTL: {
    code: "LTL",
    symbol: "Lt",
    currency: "Lithuanian Litas",
  },
  LYD: {
    code: "LYD",
    symbol: "LD",
    currency: "Libyan Dinar",
  },
  MAD: {
    code: "MAD",
    symbol: "MAD",
    currency: "Moroccan Dirham",
  },
  MDL: {
    code: "MDL",
    symbol: "lei",
    currency: "Moldovan Leu",
  },
  MGA: {
    code: "MGA",
    symbol: "Ar",
    currency: "Malagasy Ariary",
  },
  MKD: {
    code: "MKD",
    symbol: "ден",
    currency: "Macedonia Denar",
  },
  MMK: {
    code: "MMK",
    symbol: "K",
    currency: "Kyat",
  },
  MNT: {
    code: "MNT",
    symbol: "₮",
    currency: "Mongolia Tughrik",
  },
  MOP: {
    code: "MOP",
    symbol: "MOP$",
    currency: "Pataca",
  },
  MRU: {
    code: "MRU",
    symbol: "UM",
    currency: "Ouguiya",
  },
  MUR: {
    code: "MUR",
    symbol: "₨",
    currency: "Mauritius Rupee",
  },
  MVR: {
    code: "MVR",
    symbol: "Rf",
    currency: "Rufiyaa",
  },
  MWK: {
    code: "MWK",
    symbol: "MK",
    currency: "Malawi Kwacha",
  },
  MXN: {
    code: "MXN",
    symbol: "$",
    currency: "Mexican Peso",
  },
  MXV: {
    code: "MXV",
    symbol: "MXV",
    currency: "Mexican Unidad de Inversion (UDI)",
  },
  MYR: {
    code: "MYR",
    symbol: "RM",
    currency: "Malaysian Ringgit",
  },
  MZN: {
    code: "MZN",
    symbol: "MT",
    currency: "Mozambique Metical",
  },
  NAD: {
    code: "NAD",
    symbol: "$",
    currency: "Namibia Dollar",
  },
  NGN: {
    code: "NGN",
    symbol: "₦",
    currency: "Nigeria Naira",
  },
  NIO: {
    code: "NIO",
    symbol: "C$",
    currency: "Nicaragua Cordoba",
  },
  NOK: {
    code: "NOK",
    symbol: "kr",
    currency: "Norwegian Krone",
  },
  NPR: {
    code: "NPR",
    symbol: "₨",
    currency: "Nepalese Rupee",
  },
  NZD: {
    code: "NZD",
    symbol: "$",
    currency: "New Zealand Dollar",
  },
  OMR: {
    code: "OMR",
    symbol: "﷼",
    currency: "Oman Rial",
  },
  PAB: {
    code: "PAB",
    symbol: "B/.",
    currency: "Balboa",
  },
  PEN: {
    code: "PEN",
    symbol: "S/.",
    currency: "Peru Sol",
  },
  PGK: {
    code: "PGK",
    symbol: "K",
    currency: "Papua New Guinean Kina",
  },
  PHP: {
    code: "PHP",
    symbol: "₱",
    currency: "Philippine Peso",
  },
  PKR: {
    code: "PKR",
    symbol: "₨",
    currency: "Pakistan Rupee",
  },
  PLN: {
    code: "PLN",
    symbol: "zł",
    currency: "Poland Zloty",
  },
  PYG: {
    code: "PYG",
    symbol: "Gs",
    currency: "Paraguay Guarani",
  },
  QAR: {
    code: "QAR",
    symbol: "﷼",
    currency: "Qatari Rial",
  },
  RON: {
    code: "RON",
    symbol: "lei",
    currency: "Romanian Leu",
  },
  RSD: {
    code: "RSD",
    symbol: "Дин.",
    currency: "Serbian Dinar",
  },
  RWF: {
    code: "RWF",
    symbol: "R₣",
    currency: "Rwanda Franc",
  },
  SAR: {
    code: "SAR",
    symbol: "﷼",
    currency: "Saudi Arabia Riyal",
  },
  SBD: {
    code: "SBD",
    symbol: "$",
    currency: "Solomon Islands Dollar",
  },
  SCR: {
    code: "SCR",
    symbol: "₨",
    currency: "Seychelles Rupee",
  },
  SDG: {
    code: "SDG",
    symbol: "ج.س.",
    currency: "Sudanese Pound",
  },
  SEK: {
    code: "SEK",
    symbol: "kr",
    currency: "Swedish Krona",
  },
  SGD: {
    code: "SGD",
    symbol: "S$",
    currency: "Singapore Dollar",
  },
  SHP: {
    code: "SHP",
    symbol: "£",
    currency: "Saint Helena Pound",
  },
  SLL: {
    code: "SLL",
    symbol: "Le",
    currency: "Leone",
  },
  SOS: {
    code: "SOS",
    symbol: "S",
    currency: "Somali Shilling",
  },
  SRD: {
    code: "SRD",
    symbol: "$",
    currency: "Suriname Dollar",
  },
  SSP: {
    code: "SSP",
    symbol: "£",
    currency: "South Sudanese Pound",
  },
  STN: {
    code: "STN",
    symbol: "Db",
    currency: "Dobra",
  },
  SVC: {
    code: "SVC",
    symbol: "$",
    currency: "El Salvador Colon",
  },
  SYP: {
    code: "SYP",
    symbol: "£",
    currency: "Syrian Pound",
  },
  SZL: {
    code: "SZL",
    symbol: "E",
    currency: "Lilangeni",
  },
  THB: {
    code: "THB",
    symbol: "฿",
    currency: "Thailand Baht",
  },
  TJS: {
    code: "TJS",
    symbol: "SM",
    currency: "Somoni",
  },
  TMT: {
    code: "TMT",
    symbol: "T",
    currency: "Turkmenistan New Manat",
  },
  TND: {
    code: "TND",
    symbol: "د.ت",
    currency: "Tunisian Dinar",
  },
  TOP: {
    code: "TOP",
    symbol: "T$",
    currency: "Pa’anga",
  },
  TRY: {
    code: "TRY",
    symbol: "₺",
    currency: "Turkish Lira",
  },
  TTD: {
    code: "TTD",
    symbol: "TT$",
    currency: "Trinidad and Tobago Dollar",
  },
  TWD: {
    code: "TWD",
    symbol: "NT$",
    currency: "Taiwan New Dollar",
  },
  TZS: {
    code: "TZS",
    symbol: "TSh",
    currency: "Tanzanian Shilling",
  },
  UGX: {
    code: "UGX",
    symbol: "USh",
    currency: "Uganda Shilling",
  },
  UYI: {
    code: "UYI",
    symbol: "UYI",
    currency: "Uruguay Peso en Unidades Indexadas (UI)",
  },
  UYU: {
    code: "UYU",
    symbol: "$U",
    currency: "Uruguay Peso",
  },
  UYW: {
    code: "UYW",
    symbol: "UYW",
    currency: "Unidad Previsional",
  },
  VEF: {
    code: "VEF",
    symbol: "Bs",
    currency: "Venezuela Bolívar",
  },
  UZS: {
    code: "UZS",
    symbol: "лв",
    currency: "Uzbekistan Sum",
  },
  VES: {
    code: "VES",
    symbol: "Bs.S",
    currency: "Bolívar Soberano",
  },
  VND: {
    code: "VND",
    symbol: "₫",
    currency: "Vietnam Dong",
  },
  VUV: {
    code: "VUV",
    symbol: "VT",
    currency: "Vatu",
  },
  WST: {
    code: "WST",
    symbol: "WS$",
    currency: "Tala",
  },
  XAF: {
    code: "XAF",
    symbol: "FCFA",
    currency: "CFA Franc BEAC",
  },
  XCD: {
    code: "XCD",
    symbol: "$",
    currency: "East Caribbean Dollar",
  },
  XOF: {
    code: "XOF",
    symbol: "CFA",
    currency: "CFA Franc BCEAO",
  },
  XPF: {
    code: "XPF",
    symbol: "₣",
    currency: "CFP Franc",
  },
  XSU: {
    code: "XSU",
    symbol: "Sucre",
    currency: "Sucre",
  },
  XUA: {
    code: "XUA",
    symbol: "XUA",
    currency: "ADB Unit of Account",
  },
  YER: {
    code: "YER",
    symbol: "﷼",
    currency: "Yemeni Rial",
  },
  ZAR: {
    code: "ZAR",
    symbol: "R",
    currency: "South Africa Rand",
  },
  ZMW: {
    code: "ZMW",
    symbol: "ZK",
    currency: "Zambian Kwacha",
  },
  ZWL: {
    code: "ZWL",
    symbol: "$",
    currency: "Zimbabwe Dollar",
  },
};

export const lngCurMap = {
  AD: "EUR",
  AE: "AED",
  AF: "AFN",
  AG: "XCD",
  AI: "XCD",
  AL: "ALL",
  AM: "AMD",
  AO: "AOA",
  AR: "ARS",
  AS: "USD",
  AT: "EUR",
  AU: "AUD",
  AW: "AWG",
  AX: "EUR",
  AZ: "AZN",
  BA: "BAM",
  BB: "BBD",
  BD: "BDT",
  BE: "EUR",
  BF: "XOF",
  BG: "BGN",
  BH: "BHD",
  BI: "BIF",
  BJ: "XOF",
  BL: "EUR",
  BM: "BMD",
  BN: "BND",
  BO: "BOB",
  BQ: "USD",
  BR: "BRL",
  BS: "BSD",
  BT: "BTN",
  BV: "NOK",
  BW: "BWP",
  BY: "BYN",
  BZ: "BZD",
  CA: "CAD",
  CC: "AUD",
  CD: "CDF",
  CF: "XAF",
  CG: "XAF",
  CH: "CHF",
  CI: "XOF",
  CK: "NZD",
  CL: "CLP",
  CM: "XAF",
  CN: "CNY",
  CO: "COP",
  CR: "CRC",
  CU: "CUP",
  CV: "CVE",
  CW: "ANG",
  CX: "AUD",
  CY: "EUR",
  CZ: "CZK",
  DE: "EUR",
  DJ: "DJF",
  DK: "DKK",
  DM: "XCD",
  DO: "DOP",
  DZ: "DZD",
  EC: "USD",
  EE: "EUR",
  EG: "EGP",
  EH: "MAD",
  ER: "ERN",
  ES: "EUR",
  ET: "ETB",
  FI: "EUR",
  FJ: "FJD",
  FK: "FKP",
  FM: "USD",
  FO: "DKK",
  FR: "EUR",
  GA: "XAF",
  GB: "GBP",
  GD: "XCD",
  GE: "GEL",
  GF: "EUR",
  GG: "GBP",
  GH: "GHS",
  GI: "GIP",
  GL: "DKK",
  GM: "GMD",
  GN: "GNF",
  GP: "EUR",
  GQ: "XAF",
  GR: "EUR",
  GS: "GBP",
  GT: "GTQ",
  GU: "USD",
  GW: "XOF",
  GY: "GYD",
  HK: "HKD",
  HM: "AUD",
  HN: "HNL",
  HR: "HRK",
  HT: "HTG",
  HU: "HUF",
  ID: "IDR",
  IE: "EUR",
  IL: "ILS",
  IM: "GBP",
  IN: "INR",
  IO: "USD",
  IQ: "IQD",
  IR: "IRR",
  IS: "ISK",
  IT: "EUR",
  JE: "GBP",
  JM: "JMD",
  JO: "JOD",
  JP: "JPY",
  KE: "KES",
  KG: "KGS",
  KH: "KHR",
  KI: "AUD",
  KM: "KMF",
  KN: "XCD",
  KP: "KPW",
  KR: "KRW",
  KW: "KWD",
  KY: "KYD",
  KZ: "KZT",
  LA: "LAK",
  LB: "LBP",
  LC: "XCD",
  LI: "CHF",
  LK: "LKR",
  LR: "LRD",
  LS: "LSL",
  LT: "EUR",
  LU: "EUR",
  LV: "EUR",
  LY: "LYD",
  MA: "MAD",
  MC: "EUR",
  MD: "MDL",
  ME: "EUR",
  MF: "EUR",
  MG: "MGA",
  MH: "USD",
  MK: "MKD",
  ML: "XOF",
  MM: "MMK",
  MN: "MNT",
  MO: "MOP",
  MP: "USD",
  MQ: "EUR",
  MR: "MRO",
  MS: "XCD",
  MT: "EUR",
  MU: "MUR",
  MV: "MVR",
  MW: "MWK",
  MX: "MXN",
  MY: "MYR",
  MZ: "MZN",
  NA: "NAD",
  NC: "XPF",
  NE: "XOF",
  NF: "AUD",
  NG: "NGN",
  NI: "NIO",
  NL: "EUR",
  NO: "NOK",
  NP: "NPR",
  NR: "AUD",
  NU: "NZD",
  NZ: "NZD",
  OM: "OMR",
  PA: "PAB",
  PE: "PEN",
  PF: "XPF",
  PG: "PGK",
  PH: "PHP",
  PK: "PKR",
  PL: "PLN",
  PM: "EUR",
  PN: "NZD",
  PR: "USD",
  PS: "ILS",
  PT: "EUR",
  PW: "USD",
  PY: "PYG",
  QA: "QAR",
  RE: "EUR",
  RO: "RON",
  RS: "RSD",
  RU: "RUB",
  RW: "RWF",
  SA: "SAR",
  SB: "SBD",
  SC: "SCR",
  SD: "SDG",
  SE: "SEK",
  SG: "SGD",
  SH: "SHP",
  SI: "EUR",
  SJ: "NOK",
  SK: "EUR",
  SL: "SLL",
  SM: "EUR",
  SN: "XOF",
  SO: "SOS",
  SR: "SRD",
  ST: "STD",
  SV: "SVC",
  SX: "ANG",
  SY: "SYP",
  SZ: "SZL",
  TC: "USD",
  TD: "XAF",
  TF: "EUR",
  TG: "XOF",
  TH: "THB",
  TJ: "TJS",
  TK: "NZD",
  TL: "USD",
  TM: "TMT",
  TN: "TND",
  TO: "TOP",
  TR: "TRY",
  TT: "TTD",
  TV: "AUD",
  TW: "TWD",
  TZ: "TZS",
  UA: "UAH",
  UG: "UGX",
  UM: "USD",
  US: "USD",
  UY: "UYU",
  UZ: "UZS",
  VA: "EUR",
  VC: "XCD",
  VE: "VEF",
  VG: "USD",
  VI: "USD",
  VN: "VND",
  VU: "VUV",
  WF: "XPF",
  WS: "WST",
  YE: "YER",
  YT: "EUR",
  ZA: "ZAR",
  ZM: "ZMW",
  ZW: "ZWL",
};

export interface IAMOUNTFORMATVAL {
  key: string;
  holder: string;
  thousandSeparator: string;
  decimalSeparator: string;
}
export interface ICURFORMATVAL {
  key: string;
  holder: string;
  title: string;
}
export interface ICURFORMAT {
  [key: string]: ICURFORMATVAL;
}
export interface ICUR {
  [key: string]: ICURFORMATVAL;
}
export interface IAMFORMAT {
  [key: string]: IAMOUNTFORMATVAL;
}
export const CURRENCYFORMATS: ICURFORMAT = {
  // AMERICAN: {
  //   key: "AMERICAN",
  //   holder: "0.99$",
  //   title: "US standard",
  // },
  EURO: {
    key: "EURO",
    holder: "$0.99",
    title: "European standard",
  },
};
export const AMOUNTFORMATS: IAMFORMAT = {
  COMADOT: {
    key: "COMADOT",
    holder: "1,999.00",
    thousandSeparator: ",",
    decimalSeparator: ".",
  },
  DOTCOMA: {
    key: "DOTCOMA",
    holder: "1.999,00",
    thousandSeparator: ".",
    decimalSeparator: ",",
  },
};
