import { API } from 'services/http.service'
import {
  LegalEntity,
  LegalEntityData,
  FilterModel,
  LegalEntitySuggestionsData,
} from './types'

const ENDPOINT = '/v5/{accountEntityId}/legal-entities'

class LegalEntitiesService {
  async postLegalEntity(payload: LegalEntity) {
    const { data } = await API.post<LegalEntity>(ENDPOINT, {
      data: payload,
    })

    return data
  }

  async getLegalEntities(params: FilterModel) {
    const { data } = await API.get<LegalEntityData>(ENDPOINT, { params })
    return data
  }

  async getStripeSuggestions() {
    const { data } = await API.get<LegalEntitySuggestionsData>(
      `/v5/stripe/{accountEntityId}/legal-entity-suggestions`
    )
    return data
  }
}

export default new LegalEntitiesService()
