import React, { useState } from "react";

import { Button, Modal, TextArea } from "@nalezhytyi/saldo-ui";
import { observer } from "mobx-react";
import invoicesStore from "../../store/mobx/InvoicesStore";
import { Invoice, ProductLine } from "../../data/documents/types";
import { useAmplitude } from "react-amplitude-hooks";
import { TaxType } from "services/invoices-service/types";
import useNotification from "scripts/hooks/useNotification";

interface IShare {
  shareLink: string;
  onCancel: () => void;
}

const ShareLink = observer((props: IShare) => {
  const [isLoading] = useState(false);
  const { shareLink, onCancel } = props;
  const notification = useNotification()
  const data = invoicesStore.currentGeneratorDoc;
  const { logEvent } = useAmplitude();
  return (
    <Modal size="xs" open={true} backdrop>
      <Modal.Body>
        <h4>Share link</h4>
        <TextArea
          className={"mt-15"}
          name={"share link"}
          disabled={true}
          value={shareLink}
          style={{ color: "inherit" }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={"color-red"}
          loading={isLoading}
          disabled={isLoading}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        {!!navigator.clipboard.writeText && (
          <Button
            appearance={"primary"}
            loading={isLoading}
            disabled={isLoading}
            onClick={() => {
              navigator.clipboard.writeText(shareLink);
              if (data) {
                logEvent("invoice_shareInvoice_linkCopied", {
                  "Items Added": data?.documentItems?.length,
                  Discount: !!data?.discountPercentage,
                  "Shipping Cost": !!data?.shippingCostAmount,
                  "Sales Tax": !!(data.taxType === TaxType.PER_ITEM
                    ? data.documentItems.reduce((a: any, Cur: ProductLine) => {
                        return +a + +Cur.itemTaxAmount;
                      }, 0)
                    : data.taxType === TaxType.ON_TOTAL
                    ? data.taxRate
                    : undefined),
                  Notes: !!data?.notes,
                  Photos: !!data?.photos?.length,
                  "Shipping Info": !!(
                    ["shippingAddress", "shippingTrackNumber"] as Array<
                      keyof Invoice
                    >
                  ).filter((el) => {
                    if (el === "shippingAddress") {
                      return !!data[el] && !!Object.values(data[el]).join("");
                    }
                    return !!data[el];
                  }).length,
                  Currency: data?.currency?.code,
                });

                notification.push("link is copied to clipboard", {
                  type: "info",
                });
              } else logEvent("invoice_shareInvoice_linkCopied", {});
            }}
          >
            {isLoading ? "Loading..." : "Copy"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
});

export const ShareLinkHandler = observer(() => {
  const shareLink = invoicesStore.shareLink;

  return (
    (!!shareLink && (
      <ShareLink
        shareLink={shareLink}
        onCancel={() => {
          invoicesStore.shareLinkClear();
        }}
      />
    )) || <></>
  );
});
