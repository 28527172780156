import React, { FC, CSSProperties } from "react";
import classNames from "classnames";

import classes from "./Icon.module.scss";

export const solid = [
  "share",
  "credit-card",
  "exclamation",
  "pen",
  "arrow-down-left",
  "arrow-up-right",
  "plus",
  "xmark",
  "bank",
  "bike",
  "bitcoin",
  "books",
  "bus",
  "casino",
  "duck",
  "football",
  "golf",
  "home",
  "percent",
  "services",
  "ship",
  "soccer",
  "stocks",
  "wine",
  "bowling-ball",
  "cart-shopping",
  "computer-mouse",
  "cup-togo",
  "dog",
  "dumbbell",
  "fish",
  "gas-pump",
  "hockey-sticks",
  "paw",
  "piggy-bank",
  "pills",
  "scale-balanced",
  "family",
  "arrow-left",
  "arrow-right",
  "arrow-right-arrow-left",
  "baseball-bat-ball",
  "basketball",
  "bicycle",
  "bone",
  "book",
  "book-open",
  "box",
  "box-dollar",
  "briefcase-blank",
  "briefcase-medical",
  "burger",
  "camera-movie",
  "car",
  "cat",
  "chart-column",
  "chart-simple",
  "child-reaching",
  "church",
  "circle-heart",
  "coins",
  "computer",
  "crosshairs",
  "envelope",
  "file",
  "fire-flame-curved",
  "flower",
  "fork-knife",
  "gamepad-modern",
  "gift",
  "globe",
  "head-side-medical",
  "heart",
  "house",
  "island-tropical",
  "leaf",
  "martini-glass",
  "masks-theater",
  "microchip",
  "money-bill-wave",
  "mosque",
  "person",
  "phone",
  "pizza-slice",
  "plane",
  "shirt",
  "smoking",
  "spade",
  "square-parking",
  "star-of-david",
  "star-of-life",
  "suitcase-rolling",
  "synagogue",
  "taxi",
  "tooth",
  "torii-gate",
  "vacuum",
  "volleyball",
  "wallet",
  "washing-machine",
  "box-archive",
  "vihara",
  "money-bill",
  "question",
  "infinity",
  "shopping-bag",
  "user",
  "caret-up",
  "caret-down",
  "trash",
  "angle-down",
  "angle-up",
  "chevron-left",
  "chevron-right",
  "angle-left",
  "angle-right",
  "circle-dollar",
  "chart-pie",
  "bars-filter",
  "lock",
  "flag",
  "tag",
  "arrow-down-to-line",
  "sort",
  "caret-right",
  "user-plus",
  "bolt",
  "paper-plane",
  "circle-user",
  "list-ul",
  "briefcase",
  "arrow-up-right-from-square",
  "calendar",
  "synced",
  "split",
  "shield-keyhole",
  "bars",
  "circle-exclamation",
  "rotate",
  "ellipsis",
  "eye",
  "print",
  "search",
  "copy",
  "users",
  "signature",
  "chart-line",
  "user-check",
  "notes",
  "folder-arrow-down",
  "arrows-rotate",
  "eye-slash",
  "budget",
  "person-crop-circle",
  "settings",
] as const;

export const regular = [
  "regular.camera",
  "regular.chevronLeft",
  "regular.listUl",
  "regular.creditCard",
  "regular.chartMixed",
  "regular.idCardClip",
  "regular.shop",
  "regular.circleSmall",
  "regular.userGroup",
  "regular.gauge",
  "regular.xmark",
  "regular.arrow-down",
  "regular.ellipsis",
  "regular.ellipsis-vertical",
  "regular.delete-left",
  "regular.divide",
  "regular.equals",
  "regular.minus",
  "regular.percent",
  "regular.plus",
  "regular.search",
  "regular.check",
  "regular.pencil",
  "regular.user-plus",
  "regular.triangle-exclamation",
  "regular.circle-info",
  "regular.calendar",
] as const;
export const brands = [
  "brands.apple",
  "brands.google-color",
  "brands.google",
  "brands.facebook",
  "brands.saldo",
] as const;
export const light = ["light.cloud-arrow-up"] as const;

export type IconName =
  | typeof solid[number]
  | typeof regular[number]
  | typeof brands[number]
  | typeof light[number];

const Icon: FC<{
  name: IconName;
  className?: string;
  pulse?: boolean;
  color?: string;
  style?: CSSProperties;
  onClick?: (e: React.MouseEvent<SVGElement>) => void;
  onMouseDown?: (e: React.MouseEvent<SVGElement>) => void;
}> = ({ name, className, onClick, style, color = "currentColor" }) => {
  let [type, id] = ["solid", name];
  const options = name.split(".");
  if (options.length === 2) {
    type = options[0] as string;
    id = options[1] as IconName;
  }

  return (
    <svg
      fill={color}
      className={classNames(classes.icon, "rs-icon", className)}
      style={style}
      onClick={onClick}
    >
      <use href={`/icons/${type}-sprite.svg#${id}`} />
    </svg>
  );
};

export default Icon;
