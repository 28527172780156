import { API } from 'services/http.service'
import { IProfile, IProfileData } from './types'

const ENDPOINT = '/v5/im/account'

class ProfileService {
  async registerProfile() {
    const { data } = await API.post<IProfileData>(`${ENDPOINT}/register`, {
      data: { source: 'WEB' },
    })
    return data
  }

  async getProfile() {
    const { data } = await API.get<IProfileData>(`${ENDPOINT}/profile`)
    return data
  }

  async updateProfile(payload: IProfile) {
    return await API.put<IProfileData>(`${ENDPOINT}/profile`, {
      data: payload,
    })
  }
}

export default new ProfileService()
