import { configure } from 'mobx'

import authStore, { AuthStore } from './AuthStore'
import invoicesStore, { InvoicesStore } from './InvoicesStore'
import deviceStore, { DeviceStore } from './DeviceStore'
import itemsStore, { ItemsStore } from './ItemsStore'
import clientsStore, { ClientsStore } from './ClientsStore'
import paymentStore, { PaymentStore } from './PaymentStore'
import settingsStore, { SettingsStore } from './SettingsStore'
import legalEntitiesStore, { LegalEntitiesStore } from './LegalEntitiesStore'

configure({
  disableErrorBoundaries: true,
})

const StoreMobx = {
  authStore,
  deviceStore,
  invoicesStore,
  itemsStore,
  clientsStore,
  paymentStore,
  settingsStore,
  legalEntitiesStore,
}

export interface IStoreMobx {
  authStore: AuthStore
  deviceStore: DeviceStore
  invoicesStore: InvoicesStore
  itemsStore: ItemsStore
  paymentStore: PaymentStore
  clientsStore: ClientsStore
  settingsStore: SettingsStore
  legalEntitiesStore: LegalEntitiesStore
}

export default StoreMobx
