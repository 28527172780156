import {
  deleteUser,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth'
import { AuthProvider, User } from './types'
import { initializeApp } from 'firebase/app'
import TokenManager from 'manager/token.manager'
import { adminAPI } from 'services/http.service'
import LocalStorageManager from 'manager/local-storage.manager'
import accountingService from 'services/accounting-service/accounting.service'

const ENDPOINT = '/v1/user'

const firebaseConfig = {
  apiKey: 'AIzaSyCmgq13UzwjEjSlrSZ8T-OGVENrOvqZqTw',
  authDomain: 'amabayallapps.firebaseapp.com',
  databaseURL: 'https://amabayallapps.firebaseio.com',
  projectId: 'amabayallapps',
  storageBucket: 'amabayallapps.appspot.com',
  messagingSenderId: '1055065970573',
  appId: '1:1055065970573:web:c8efdec717009858a5cfc4',
  measurementId: 'G-XPSVMJCPNG',
}

export type SingInOptionsType = {
  provider: AuthProvider
  onLoadToken?: (token: string) => void
}

class AuthService {
  private refreshTokenTimeout?: NodeJS.Timeout

  get firebaseAuth() {
    return getAuth(initializeApp(firebaseConfig))
  }

  async singInByProvider(options: SingInOptionsType) {
    let provider

    switch (options.provider) {
      case 'facebook':
        provider = new FacebookAuthProvider()
        break
      case 'apple':
        provider = new OAuthProvider('apple.com')
        break
      default:
        provider = new GoogleAuthProvider()
    }

    const data = await signInWithPopup(this.firebaseAuth, provider)
    const credential = OAuthProvider.credentialFromResult(data)
    const token =
      options.provider === 'facebook'
        ? credential?.accessToken
        : credential?.idToken

    if (!token) {
      throw new Error('No token')
    }

    if (this.firebaseAuth.currentUser) {
      await deleteUser(this.firebaseAuth.currentUser)
    }

    if (options.onLoadToken) {
      options.onLoadToken(token)
    }

    const {
      data: { jwt, user },
    } = await adminAPI.post<{
      jwt: { accessToken: string; refreshToken: string }
      user: User
    }>(`/v1/user/auth/${options.provider}`, {
      data: {
        device: 'string',
        deviceUid: 'string',
        ref: 'string',
        token,
      },
      headers: {
        bundle:
          options.provider === 'apple' ? 'com.saldoinvoice.dev' : 'string',
      },
    })

    TokenManager.set(jwt)
    this.startRefreshTokenTimer()
    await this.setHeadersData(user)
    await accountingService.invokeAccountingEntity()

    return user
  }

  async signInByEmail(email: string) {
    return await adminAPI.post(`${ENDPOINT}/auth/email`, {
      data: { email },
    })
  }

  async loadCurrentUser() {
    const { data: user } = await adminAPI.get<User>(ENDPOINT)
    await this.setHeadersData(user)
    await accountingService.invokeAccountingEntity()

    return user
  }

  async setHeadersData(user: User) {
    LocalStorageManager.set('customerId', user.customerId)
    LocalStorageManager.set('customerUid', user.customerUid)
    LocalStorageManager.set('ssoUserId', user.id)
    LocalStorageManager.set('userEmail', user.email)
  }

  async confirmAuth(code: string) {
    const {
      data: { jwt, user },
    } = await adminAPI.post<{
      jwt: { accessToken: string; refreshToken: string }
      user: User
    }>(`${ENDPOINT}/auth/confirmation`, {
      data: {
        code,
        device: '',
        deviceUid: '',
        ref: '',
      },
    })

    TokenManager.set(jwt)
    this.startRefreshTokenTimer()

    return user
  }

  async trySingIn() {
    let user = null
    if (!TokenManager.isExpiredAccessToken()) {
      user = await this.loadCurrentUser()
    } else if (!TokenManager.isExpiredRefreshToken()) {
      user = await this.refreshToken()
    }

    if (user) {
      this.startRefreshTokenTimer()
    }

    return user
  }

  private async refreshToken() {
    try {
      const {
        data: { jwt, user },
      } = await adminAPI.post<{
        jwt: { accessToken: string; refreshToken: string }
        user: User
      }>(`${ENDPOINT}/auth/refresh-token`, {
        data: {
          refreshToken: TokenManager.getRefreshToken(),
        },
      })
      TokenManager.set(jwt)

      return user
    } catch (error) {
      console.error('Auth <refreshToken>: failed to refresh token')
    }
  }

  private startRefreshTokenTimer() {
    if (this.refreshTokenTimeout) {
      clearTimeout(this.refreshTokenTimeout)
    }
    this.refreshTokenTimeout = setTimeout(async () => {
      if (TokenManager.isExpiredAccessToken()) {
        await this.refreshToken()
      }
      this.startRefreshTokenTimer()
    }, 5 * 60 * 1000) // check access token every 5 minutes
  }

  signOut() {
    if (this.refreshTokenTimeout) {
      clearTimeout(this.refreshTokenTimeout)
    }
    TokenManager.clear()
  }
}

export default new AuthService()
