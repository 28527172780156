import { API } from 'services/http.service'

class FileService {
  async postFile(data: FormData) {
    const {
      data: { link },
    } = await API.post<{ link: string }>('/v5/im/file', {
      data,
    })
    return link
  }
}

export default new FileService()
