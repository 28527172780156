import { dayjs } from 'utils/days'

export enum UIFormatterType {
  US_FORMAT = 'US_FORMAT',
  CANADA_FORMAT = 'CANADA_FORMAT',
  UK_FORMAT = 'UK_FORMAT',
  EU_FORMAT = 'EU_FORMAT',
}

enum UIFormatterSetting {
  DateShort,
  DateMedium,
  DateLong,
  DateTime,
  DecimalSeparator,
  GroupingSeparator,
  CurrencySymbolPosition,
  CurrencySymbolSpace,
}

const UI_FORMATS = {
  [UIFormatterType.US_FORMAT]: {
    [UIFormatterSetting.DateShort]: 'MM/DD/YY',
    [UIFormatterSetting.DateMedium]: 'MMM DD, YYYY',
    [UIFormatterSetting.DateLong]: 'MMMM DD, YYYY',
    [UIFormatterSetting.DateTime]: 'h:mm A',
    [UIFormatterSetting.DecimalSeparator]: '.',
    [UIFormatterSetting.GroupingSeparator]: ',',
    [UIFormatterSetting.CurrencySymbolPosition]: 'BEFORE',
    [UIFormatterSetting.CurrencySymbolSpace]: false,
  },
  [UIFormatterType.CANADA_FORMAT]: {
    [UIFormatterSetting.DateShort]: 'DD/MM/YY',
    [UIFormatterSetting.DateMedium]: 'DD-MMM-YYYY',
    [UIFormatterSetting.DateLong]: 'MMMM DD, YYYY',
    [UIFormatterSetting.DateTime]: 'h:mm A',
    [UIFormatterSetting.DecimalSeparator]: '.',
    [UIFormatterSetting.GroupingSeparator]: ',',
    [UIFormatterSetting.CurrencySymbolPosition]: 'BEFORE',
    [UIFormatterSetting.CurrencySymbolSpace]: false,
  },
  [UIFormatterType.UK_FORMAT]: {
    [UIFormatterSetting.DateShort]: 'DD/MM/YY',
    [UIFormatterSetting.DateMedium]: 'DD-MMM-YYYY',
    [UIFormatterSetting.DateLong]: 'DD MMMM YYYY',
    [UIFormatterSetting.DateTime]: 'HH:mm',
    [UIFormatterSetting.DecimalSeparator]: '.',
    [UIFormatterSetting.GroupingSeparator]: ',',
    [UIFormatterSetting.CurrencySymbolPosition]: 'BEFORE',
    [UIFormatterSetting.CurrencySymbolSpace]: false,
  },
  [UIFormatterType.EU_FORMAT]: {
    [UIFormatterSetting.DateShort]: 'DD.MM.YY',
    [UIFormatterSetting.DateMedium]: 'DD.MM.YYYY',
    [UIFormatterSetting.DateLong]: 'DD. MMMM YYYY',
    [UIFormatterSetting.DateTime]: 'HH:mm',
    [UIFormatterSetting.DecimalSeparator]: ',',
    [UIFormatterSetting.GroupingSeparator]: '.',
    [UIFormatterSetting.CurrencySymbolPosition]: 'AFTER',
    [UIFormatterSetting.CurrencySymbolSpace]: true,
  },
}

export const UIFormatter = (format: UIFormatterType = UIFormatterType.US_FORMAT) => {
  const config = UI_FORMATS[format]

  return {
    date(value: number, type?: 'short' | 'medium' | 'long') {
      let format = UIFormatterSetting.DateShort

      if (type === 'medium') {
        format = UIFormatterSetting.DateMedium
      }
      if (type === 'long') {
        format = UIFormatterSetting.DateLong
      }

      return dayjs(value).format(config[format])
    },

    time(value: number) {
      return dayjs(value).format(config[UIFormatterSetting.DateTime])
    },

    number(
      value: number,
      options?: {
        minimumFractionDigits?: number | undefined
        maximumFractionDigits?: number | undefined
      }
    ) {
      let locale = 'en-US'
      if (
        config[UIFormatterSetting.DecimalSeparator] === ',' &&
        config[UIFormatterSetting.GroupingSeparator] === '.'
      ) {
        locale = 'en-DE'
      }
      return new Intl.NumberFormat(locale, {
        minimumFractionDigits: options?.minimumFractionDigits || 0,
        maximumFractionDigits: options?.maximumFractionDigits || 2,
      }).format(value)
    },

    percentage(value: number) {
      return this.number(value) + '%'
    },

    amount(value: number, symbol: string) {
      const symbolPosition = config[UIFormatterSetting.CurrencySymbolPosition]
      const symbolSpace = config[UIFormatterSetting.CurrencySymbolSpace]

      let amount = `${symbol}|${this.number(value)}`
      if (symbolPosition === 'AFTER') {
        amount = `${this.number(value)}|${symbol}`
      }

      return amount.replace('|', symbolSpace ? ' ' : '')
    },
  }
}
