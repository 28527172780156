const localeKeys = {
  main_Continue: "Continue",
  main_Sign_in: "Sign in",
  main_Terms_of_Use: "Terms of Use",
  main_Privacy_Policy: "Privacy Policy",
  main_and: "and",
  main_Back: "Back",

  pages_login_title: "Sign Up",
  pages_login_title_2: "Sign Up",
  pages_login_sign_in_title: "Log in",
  pages_login_Resend_Code: "Resend Code",
  pages_login_by_sign_in: "By signing in, you agree to our",
  pages_login_apple_btn: "Log in with Apple",

  pages_business_title: "Legal entity",

  errors_messages_any: "Sing in error, please",
};

export default localeKeys;
