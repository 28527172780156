import React, { FC } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import localeKeys from 'locale'
import authStore from 'store/mobx/AuthStore'
import settingsStore from 'store/mobx/SettingsStore'
import { Button } from '@nalezhytyi/saldo-ui'
import Icon from 'components/Icon'
export interface AppleAuthProps extends RouteComponentProps {
  disabled?: boolean
  gtmClassName?: string
  onClickHandler: () => void
  onSuccessHandler: (isNewUser: boolean) => void
  iconButton?: boolean
}

const AppleAuth: FC<AppleAuthProps> = ({
  disabled,
  onClickHandler,
  onSuccessHandler,
  gtmClassName,
  iconButton,
}) => {
  const onClick = async () => {
    if (disabled) return
    onClickHandler()

    await authStore.singIn('apple', onSuccessHandler)
    await settingsStore.getSettings()
  }

  if (iconButton)
    return (
      <Button
        block
        onClick={onClick}
        disabled={disabled}
        style={{
          background: '#000',
          textAlign: 'center',
          border: '1px solid',
          padding: '14px',
          marginTop: 0
        }}
        appearance="primary"
      >
        <Icon
          className="fz18"
          name="brands.apple"
          style={{ verticalAlign: 'middle' }}
        />
        <div className={'gtm_button ' + gtmClassName} />
      </Button>
    )

  return (
    <Button
      style={{ backgroundColor: 'black' }}
      appearance="primary"
      block
      onClick={onClick}
      disabled={disabled}
    >
      <Icon name="brands.apple" className="fz18" style={{ float: 'left' }} />
      <span>{localeKeys.pages_login_apple_btn}</span>
      <div className={'gtm_button ' + gtmClassName} />
    </Button>
  )
}
export default withRouter(AppleAuth)
