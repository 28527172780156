import { makeObservable, action, observable, runInAction } from 'mobx'
import { createContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  IFilterModel,
  IClient,
  IClientsData,
} from 'services/clients-service/types'
import clientsService from 'services/clients-service/clients.service'
import { toast } from 'react-toastify'

const initialAddress = {
  addressLine1: "",
  addressLine2: "",
  postalCode: "",
  countryIsoCode: "",
  country: "",
  city: "",
  state: "",
};

const initialEditorData = {
  id: '',
  name: '',
  billingAddress: {
    ...initialAddress,
  },
  archived: false,
  shippingAddress: {
    ...initialAddress,
  },
  createdAt: Date.now(),
}

export class ClientsStore {
  clientsData: IClientsData
  filterModel: IFilterModel
  editorData: IClient
  edited: boolean
  isEditorOpen: boolean
  isLoading: boolean

  constructor() {
    makeObservable(this, {
      clientsData: observable,
      filterModel: observable,
      getClients: action,
      updateFilter: action,
      postClient: action,
      resetClients: action,
      editorData: observable,
      edited: observable,
      isEditorOpen: observable,
      isLoading: observable,
      toggleEditor: action,
      closeEditor: action,
      changeData: action.bound,
    })

    this.filterModel = {
      sortBy: 'createdAt',
      search: undefined,
      offset: 0,
      limit: 10,
      sortType: 'DESC',
    }

    this.clientsData = {
      offset: 0,
      total: 0,
      results: [],
    }
    this.editorData = { ...initialEditorData }
    this.edited = false
    this.isEditorOpen = false
    this.isLoading = false
  }

  toggleEditor = () => {
    this.isEditorOpen = !this.isEditorOpen
  }

  closeEditor = () => {
    this.edited = false
    this.isEditorOpen = false
  }

  async edit(id?: string | number) {
    const curItem = this.clientsData?.results?.find((i) => i.id === id)
    this.editorData = { ...this.editorData, ...curItem }
    this.toggleEditor()
  }

  create() {
    this.editorData = { ...initialEditorData }
    this.toggleEditor()
  }

  changeData(value: any, key?: string, nestedKey?: string) {
    this.edited = true
    if (!key) return
    if (key && nestedKey) {
      return (this.editorData[key][nestedKey] = value)
    }
    this.editorData[key] = value
  }

  async postClient(
    data: IClient | any,
    isCreate?: boolean,
    successHandler?: (notes: boolean) => void
  ) {
    const _data = { ...data }
    _data.updatedAt = Date.now()
    _data.createdAt = data.createdAt || Date.now()
    _data.archived = !!data.archived
    _data.id = _data.id || uuidv4()

    runInAction(() => {
      this.isLoading = true
    })

    try {
      await clientsService.postClient(_data)

      if (_data.archived) {
        toast.info(`client ${_data.name + ' ' || ' '}deleted`)
      } else {
        if (isCreate) {
          toast.info(`client ${_data.name + ' ' || ' '}created`)
          if (successHandler) successHandler(!!data.note)
        } else {
          toast.info(`client ${_data.name + ' ' || ' '}updated`)
        }
      }
      await this.getClients()
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async updateFilter(_filterModel?: IFilterModel) {
    await this.getClients(_filterModel)
  }

  getArrBySearch = async (search: string) => {
    const data = await clientsService.getClient({ search })
    return data?.results.map((el) => {
      return {
        label: el.name,
        value: el.name,
        data: el,
      }
    })
  }

  async getClients(_filterModel?: IFilterModel) {
    this.isLoading = true
    if (_filterModel) {
      this.filterModel = { ...this.filterModel, ..._filterModel }
    }

    try {
      const data = await clientsService.getClient(this.filterModel)

      runInAction(() => {
        this.clientsData = {
          offset: data.offset,
          total: data.total,
          results: data.results.map((client) => {
            return {
              ...client,
              billingAddress: {
                ...initialAddress,
                ...(client.billingAddress || {}),
              },
              shippingAddress: {
                ...initialAddress,
                ...(client.shippingAddress || {}),
              },
            }
          }),
        }
      })
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  resetClients() {
    this.clientsData = {
      offset: 0,
      total: 0,
      results: [],
    }
  }

  resetFiltration() {
    this.filterModel = {
      sortBy: 'createdAt',
      search: undefined,
      offset: 0,
      limit: 10,
      sortType: 'DESC',
    }
  }
}

const clientsStore = new ClientsStore()
export const clientsStoreContext = createContext(clientsStore)
export default clientsStore
