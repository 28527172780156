import React from "react";
import { observer } from "mobx-react";
import {
  useStripe,
  CardElement,
  Elements,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "@nalezhytyi/saldo-ui";
import { loadStripe } from "@stripe/stripe-js";
import settingsStore from "store/mobx/SettingsStore";

import css from "./styles.module.scss";

interface IProps {
  setIsLoading: (isLoading: boolean) => void;
  actionEnabled?: boolean;
  onSave: (token?: string) => Promise<any>;
}

export const CardForm = (props: IProps) => {
  const pkTest = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';

  const stripePromise = loadStripe(pkTest);

  return (
    <Elements key={"en"} stripe={stripePromise} options={{ locale: "en" }}>
      <SubscribeForm {...props} />
    </Elements>
  );
};

const SubscribeForm = observer(
  ({ onSave, setIsLoading, actionEnabled }: IProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const { currentStripePlan } = settingsStore;

    const handleSubmit = async () => {
      setIsLoading(true);
      const cardEl = elements?.getElement(CardElement);
      if (stripe && cardEl) {
        const result = await stripe.createToken(cardEl);
        await onSave(result?.token?.id);
      } else {
        setIsLoading(false);
      }
    };

    return (
      <>
        <div className={css.inputLabel}>
          Card information
        </div>
        <div className={css.stripeFormWrap}>
          <CardElement onReady={(element => element.focus())} className={css.stripeInput} />
        </div>
        <Button
          loading={!stripe || !actionEnabled}
          disabled={!stripe || !actionEnabled}
          appearance="primary"
          size="md"
          block
          className={css.paywallButton}
          onClick={handleSubmit}
        >
          Subscribe ${currentStripePlan.price}
        </Button>
        <div className={css.description}>
          Your subscription will be set to auto-renewal payments of ${currentStripePlan.price} per
          month. You can cancel your subscription at any time to avoid future charges.
          You also agree to our <a href="https://telegra.ph/Terms-of-Use-11-08" target='_blank' rel="noreferrer">Terms&nbsp;of Service</a> and{" "}
          <a href="https://telegra.ph/Privacy-Policy-11-08-8" target='_blank' rel="noreferrer">Privacy Policy</a>.
        </div>
      </>
    );
  }
);
