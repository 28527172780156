import { v4 as uuidv4 } from "uuid";
import {
  AMOUNTFORMATS,
  CURRENCIES,
  CURRENCYFORMATS,
  ICURRENCYVAL,
  lngCurMap,
} from "../data/settings";
import { Invoice } from "../data/documents/types";
import { IPaymentOption, METHODTYPE } from "../services/payment-service/types";
import { PaymentOptions } from "services/legal-entities-service/types";

const helpers = {
  getAmount: (amount: number | string = 0): string => {
    const storedAmountFormat = localStorage.getItem("IM_amount_format");
    const _value = amount || 0;

    let _tempValue = (+_value / 100).toFixed(2);
    if (
      storedAmountFormat &&
      storedAmountFormat === AMOUNTFORMATS.DOTCOMA.key
    ) {
      _tempValue = parseFloat(_tempValue).toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    if (
      storedAmountFormat &&
      storedAmountFormat === AMOUNTFORMATS.COMADOT.key
    ) {
      _tempValue = parseFloat(_tempValue).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return _tempValue;
  },
  getNumValue: (value: number | string | null | undefined): string => {
    const a = String(value || "").replace(/[,-]/g, "");
    return a;
  },

  convertAmountToCoin(value = "0") {
    const _value = value || "0";

    return String(Math.floor(
      (+parseFloat(String(_value).replace(/[^-0-9.]/g, "")).toFixed(2) * 1000) /
        10
    ));
  },

  getCurrency: (code?: string): ICURRENCYVAL => {
    const storedCode = code || localStorage.getItem("IM_currency_code");
    if (storedCode && CURRENCIES[storedCode]) {
      return CURRENCIES[storedCode];
    }
    const lng = window.navigator.language.slice(-2);
    // @ts-ignore
    if (lngCurMap[lng]) {
      // @ts-ignore
      return CURRENCIES[lngCurMap[lng]];
    } else {
      return CURRENCIES.USD;
    }
  },
  getCurrencyFormat: () => {
    const storedCurrencyFormat = localStorage.getItem("IM_currency_format");
    if (storedCurrencyFormat && storedCurrencyFormat in CURRENCYFORMATS) {
      // @ts-ignore
      return CURRENCYFORMATS[storedCurrencyFormat];
    }

    const lng = window.navigator.language;
    if (
      /^de\b/.test(lng) ||
      /^it\b/.test(lng) ||
      /^es\b/.test(lng) ||
      /^fr\b/.test(lng) ||
      /^el\b/.test(lng) ||
      /^nl\b/.test(lng) ||
      /^uk\b/.test(lng) ||
      /^ru\b/.test(lng)
    ) {
      return CURRENCYFORMATS.EURO;
    } else {
      return CURRENCYFORMATS.AMERICAN;
    }
  },
  getAmountFormat: () => {
    const storedAmountFormat = localStorage.getItem("IM_amount_format");
    if (storedAmountFormat && storedAmountFormat in AMOUNTFORMATS) {
      // @ts-ignore
      return AMOUNTFORMATS[storedAmountFormat];
    }

    return AMOUNTFORMATS.COMADOT;
  },
  getRestrictProcessingMode: () => {
    const storedRPMode = localStorage.getItem("IM_restrict_processing_mode");
    return storedRPMode !== "false";
  },

  getFileUrl: (uuid: string) => {
    if (!uuid) return "";
    if (uuid.includes("base64") || uuid.includes("http")) return uuid;
    return process.env.REACT_APP_STORE_URL + "/" + uuid + ".png";
  },

  getFormDataFromBase64: (base64: string) => {
    function DataURIToBlob(dataURI: string) {
      const splitDataURI = dataURI.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    }

    const file = DataURIToBlob(base64 || "");

    // const newFileName = uuidv4();
    const baseUUID = uuidv4();
    const newFileName =
      baseUUID +
      "." +
      base64.substring("data:image/".length, base64.indexOf(";base64"));
    const formData = new FormData();
    formData.append("file", file, newFileName);
    return { formData, newFileName: baseUUID };
  },
  getOptByType(payOptions: IPaymentOption[], type: METHODTYPE) {
    return (payOptions.find((el) => el.type === type) || {}).payload;
  },
  getPaymentInstrFromOptions(payOptions: PaymentOptions) {
    const pPe = payOptions?.paypalEmail;
    const payEmail = !!pPe ? `PayPal email:\n${pPe}` : "";
    const pBa = payOptions?.bankTransfer
    const payBA = !!pBa ? `Bank transfer:\n${pBa}` : "";
    const pCh = payOptions?.check
    const payCheck = !!pCh ? `Make checks payable to:\n${pCh}` : "";
    const pO = payOptions?.other
    const payOther = !!pO ? `${pO}` : "";

    const payInstr = [payEmail, payBA, payCheck, payOther]
      .filter((el) => !!el)
      .join("\n\n");
    return String(payInstr);
  },

  getDate(date?: Date | number | string) {
    if (!date) return "";
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  },

  getPercentage(num: string) {
    return isNaN(parseFloat(num))
      ? 0
      : this.isInt(parseFloat(num).toFixed(2))
      ? parseInt(num)
      : parseFloat(num).toFixed(2);
  },
  isInt(n: string) {
    const _n = isNaN(parseFloat(n)) ? 0 : parseFloat(n) || 0;
    return _n % 1 === 0;
  },
  getInitials: (name: string) => {
    if (!name) {
      return "";
    }
    const tmp = name.toUpperCase().trim().split(" ");
    if (tmp.length === 1) {
      return tmp[0].slice(0, 2);
    }
    if (!tmp[1]) {
      return tmp[0].slice(0, 2);
    }
    return `${tmp[0][0]}${tmp[1][0]}`;
  },
  validateGenInvoice: function (invoice: Invoice) {
    for (let i = 0; i < invoice.documentItems?.length; i++) {
      if (
        !invoice.documentItems[i].itemName ||
        !invoice.documentItems[i].itemPrice
      ) {
        return false;
      }
    }
    const isValidComEmail =
      !invoice.companyEmail ||
      (!!invoice.companyEmail && this.isValidEmail(invoice.companyEmail));
    const isValidComPhone =
      !invoice.companyPhone ||
      (!!invoice.companyPhone && this.isValidPhone(invoice.companyPhone));
    const isValidComMobile =
      !invoice.companyMobile ||
      (!!invoice.companyMobile && this.isValidPhone(invoice.companyMobile));
    const isValidCompanyWebsite =
      !invoice.companyWebsite ||
      (!!invoice.companyWebsite && this.isValidURL(invoice.companyWebsite));

    const isValidClientEmail =
      !invoice.clientEmail ||
      (!!invoice.clientEmail && this.isValidEmail(invoice.clientEmail));
    const isValidClientPhone =
      !invoice.clientPhone ||
      (!!invoice.clientPhone && this.isValidPhone(invoice.clientPhone));
    const isValidClientMobile =
      !invoice.clientMobile ||
      (!!invoice.clientMobile && this.isValidPhone(invoice.clientMobile));
    // const isValidPayEmail =
    //   !invoice.payEmail ||
    //   (!!invoice.payEmail && this.isValidEmail(invoice.payEmail));
    return (
      !!invoice.companyName &&
      !!invoice.clientName &&
      isValidComEmail &&
      isValidComPhone &&
      isValidComMobile &&
      isValidClientEmail &&
      isValidClientPhone &&
      isValidClientMobile &&
      // isValidPayEmail &&
      isValidCompanyWebsite
    );
  },
  validateBaseInvoice: function (invoice: any) {
    for (let i = 0; i < invoice.documentItems?.length; i++) {
      if (
        !invoice.documentItems[i].itemName ||
        !invoice.documentItems[i].price
      ) {
        return false;
      }
    }
    const com = invoice.documentLegalEntity || {};
    const isValidComEmail =
      !com.email || (!!com.email && this.isValidEmail(com.email));
    const isValidComPhone =
      !com.phone || (!!com.phone && this.isValidPhone(com.phone));
    const isValidCompanyWebsite =
      !com.website || (!!com.website && this.isValidURL(com.website));

    const cli = invoice.documentClient || {};

    const isValidClientEmail =
      !cli.email || (!!cli.email && this.isValidEmail(cli.email));
    const isValidClientPhone =
      !cli.phone || (!!cli.phone && this.isValidPhone(cli.phone));
    const isValidClientMobile =
      !cli.mobilePhone ||
      (!!cli.mobilePhone && this.isValidPhone(cli.mobilePhone));
    // const isValidPayEmail =
    //   !invoice.payEmail ||
    //   (!!invoice.payEmail && this.isValidEmail(invoice.payEmail));
    return (
      !!com.businessName &&
      !!cli.name &&
      isValidComEmail &&
      isValidComPhone &&
      isValidClientEmail &&
      isValidClientPhone &&
      isValidClientMobile &&
      // isValidPayEmail &&
      isValidCompanyWebsite
    );
  },

  incrementINumber: (): string => {
    const lsVal = localStorage?.getItem("IM_documentNumber");

    function incrementer(value: string | null): string {
      if (!value) {
        localStorage?.setItem("IM_documentNumber", "001");

        return "001";
      }

      const reg = /(\d+)(?!.*\d)/g;
      const match = reg.exec(value);

      if (match) {
        const startNulls = match[0].match(/^0+/);
        const startNullsLength = startNulls ? startNulls[0].length : 0;

        const prefix = value.slice(0, match.index + startNullsLength);
        const suffix = value.slice(match.index + match[0].length);
        const num = Number(match[0]) + 1;

        localStorage?.setItem("IM_documentNumber", prefix + num + suffix);

        return prefix + num + suffix;
      } else {
        return "001";
      }
    }

    return incrementer(lsVal);
  },

  isValidEmail: (email: any) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  isValidPhone: (phone: any) => {
    const re = /^[+]?[\d]?[(]?[0-9]{1,3}[)]?[0-9-\s.]{6,30}$/;
    return re.test(String(phone).toLowerCase());
  },

  isValidURL: (url: any) => {
    const re =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
    return re.test(String(url).toLowerCase());
  },
  decimalFormat: (value: string| number = 0, fractionDigits = 2) =>
    new Intl.NumberFormat(navigator.language, {
      minimumFractionDigits: fractionDigits,
    }).format(Number(value)),
};

export default helpers;
