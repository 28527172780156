import { CSSProperties } from "react";
import { UIFormatterType } from "utils/ui-formatter";

export enum DOCUMENTTYPE {
  INV = "INV",
  EST = "EST",
}

export enum PaidState {
  STATUS_PARTIALLY_PAID = "STATUS_PARTIALLY_PAID",
  STATUS_PAID = "STATUS_PAID",
  STATUS_UNPAID = "STATUS_UNPAID",
}

export interface ProductLine {
  _id?: string;
  itemName: string;
  itemDescription: string;
  qty: string;
  itemTaxName: string;
  itemTax: string;
  itemTaxAmount: string;
  itemPrice: string;
  itemTotal: string;
  itemSubTotal: string;
  itemOrder: string;
  itemDiscountType: string;
  itemDiscountPercentage: string;
  itemDiscountAmount: string;
  isTaxable: boolean;
}

export enum TaxType {
  PER_ITEM = 'PER_ITEM',
  ON_TOTAL = 'ON_TOTAL',
  NONE = 'NONE',
}

export type Currency = {
  code: string
  symbol: string
}

export type Template = {
  number: number
  color: string
}

export type Address = {
  addressLine1: string;
  addressLine2: string;
  zip: string;
  country: string;
  countryIsoCode: string;
  city: string;
  state: string;
};
export type TPayment = {
  id: string;
  amount: string;
  type: string;
  updatedAt: string;
  createdAt: string;
};
export type TDocHistory = {
  type: string;
  createdAt: string;
};

export interface Invoice {
  __type?: DOCUMENTTYPE;
  base64: {
    _logo: string;
    _signature: string;
    _photos: string[];
  };
  archived: boolean;
  id: string;
  logo: string;
  signature: string;

  discountPercentage: string;
  discountType: string;
  discountAmount: string;
  amountPaid: string;
  discountLabel: string;

  documentType: string;
  companyId: string;
  companyName: string;
  companyPhone: string;
  companyEmail: string;
  companyWebsite: string;
  companyAddress: Address;
  companyNotes: string;
  disablePaymentLinks?: boolean;
  billTo: string;
  billFrom: string;
  shipTo: string;
  clientId: string;
  clientName: string;
  clientBusinessName: string;
  clientMobile: string;
  clientPhone: string;
  clientEmail: string;
  clientBillingAddress: Address;

  invoiceTitleLabel: string;
  documentNumber: string;
  issueDateLabel: string;
  issueDate: string;
  dueDateLabel: string;
  dueDate: string;

  productLineDescription: string;
  productLineQuantity: string;
  productLineTax: string;
  productLineDisc: string;
  productLineQuantityRate: string;
  productLineQuantityAmount: string;

  documentItems: ProductLine[];

  subTotalLabel: string;
  subTotal: string;
  taxLabel: string;
  taxRate: string;

  totalLabel: string;
  total: string;
  amountPaidLabel: string;
  balanceLabel: string;
  currency: Currency;

  paymentInstructions: string;
  paymentLabel: string;
  // payEmail: string;
  // payPayableTo: string;
  // payBankTransfer: string;
  // payOther: string;

  notesLabel: string;
  notes: string;

  shippingCostAmount: string;
  shippingCostLabel: string;
  shippingAddress: Address;
  shippingTrackNumber: string;

  dimensions: {
    width: string | number;
    height: string | number;
  };

  photosLabel: string;
  photos: string[];
  photosDimensions: {
    [key: number]: {
      width: string | number;
      height: string | number;
    };
  };

  taxIncluded: boolean;
  taxType: string;

  clientNote: string;
  terms: string;
  sentState: string;
  dueType: string;
  createdAt: string;
  clientShippingAddress: Address;
  companyTaxNumber: string;
  payments: TPayment[];
  docHistory: TDocHistory[];
  stripeLink: string;
  uiFormatter: UIFormatterType
}

export interface CSSClasses {
  [key: string]: CSSProperties;
}

export enum AMPLITUDEINVOICESOURCE {
  ALLDOCS = 'All Docs Dropdown',
  INVOICE = 'Invoice Details'
}

export enum AMPLITUDEINVOICEACTIONTYPE {
  SHARE = 'Share',
  SEND = 'Send',
  DOWNLOAD = 'Download',
  PREVIEW = 'Preview',
  DUPLICATE = 'Duplicate',
  DELETE = 'Delete'
}
