import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import hash from 'object-hash'
import authStore from 'store/mobx/AuthStore'
import TokenManager from 'manager/token.manager'
import LocalStorageManager from 'manager/local-storage.manager'
import { toast } from 'react-toastify'

const getRequestHash = (config: AxiosRequestConfig) => {
  return hash({
    url: config.url,
    method: config.method,
    params: config.params,
  })
}

const createClient = (config?: AxiosRequestConfig) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const requests = new Map()
  const client = axios.create({
    ...config,
    headers: {
      locale: window.navigator.language,
      timeZoneId: timezone,
      Accept: 'application/json',
      platform: 'WEB',
      appSource: 'INVOICE_MAKER_2',
    },
  })

  client.interceptors.request.use(function (config) {
    const token = TokenManager.getAccessToken()
    const { customerId, ssoUserId, accountEntityId } =
      LocalStorageManager.getAll(['customerId', 'ssoUserId', 'accountEntityId'])
    const headers = { ...config.headers }
    const url = config.url?.replace(`{accountEntityId}`, accountEntityId || '')
    if (customerId) headers['customer-id'] = customerId
    if (ssoUserId) headers['user-id'] = ssoUserId

    if (token) headers.token = token

    return {
      ...config,
      url,
      headers,
    }
  })

  client.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const { status, data } = error.response || {}
      if (status === 401) {
        authStore.logOut()
        return
      }
      //for register & get invoice logic ¯\_(ツ)_/¯
      if (status === 404) {
        return error
      }
      if (status !== 404) {
        let message = 'Oops... something went wrong'

        if (data?.length > 0) {
          const [error] = data
          message = error?.description
        }

        toast.error(message)
      }

      return Promise.reject(error)
    }
  )

  const request = <T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> => {
    if (config.method?.toLowerCase() === 'get') {
      const requestHash = getRequestHash(config)
      if (!requests.has(requestHash)) {
        const promise = client.request(config)
        promise.finally(() => {
          requests.delete(requestHash)
        })
        requests.set(requestHash, promise)
      }
      return requests.get(requestHash)
    }

    return client.request(config)
  }

  return {
    get: <T>(url: string, config?: AxiosRequestConfig) =>
      request<T>({ ...config, url, method: 'get' }),
    post: <T>(url: string, config?: AxiosRequestConfig) =>
      request<T>({ ...config, url, method: 'post' }),
    delete: <T>(url: string, config?: AxiosRequestConfig) =>
      request<T>({ ...config, url, method: 'delete' }),
    put: <T>(url: string, config?: AxiosRequestConfig) =>
      request<T>({ ...config, url, method: 'put' }),
    patch: <T>(url: string, config?: AxiosRequestConfig) =>
      request<T>({ ...config, url, method: 'patch' }),
  }
}

export const API = createClient({
  baseURL: process.env.REACT_APP_API_URL,
})

export const adminAPI = createClient({
  baseURL: process.env.REACT_APP_ADMIN_API_URL,
})

export const subscriptionsAPI = createClient({
  baseURL: process.env.REACT_APP_SUBSCRIPTIOS_URL,
})
