import { API } from 'services/http.service'
import { IItem, IItemsData, IFilterModel } from './types'

const ENDPOINT = '/v5/{accountEntityId}/items'

class ItemsService {
  async postItem(data: IItem) {
    return await API.post(ENDPOINT, {
      data,
    })
  }

  async getItems(params: IFilterModel) {
    const { data } = await API.get<IItemsData>(ENDPOINT, { params })
    return data
  }
}

export default new ItemsService()
