import { API } from 'services/http.service'
import { IndustriesData } from './types'

class IndustriesService {
  async getIndustries() {
    const { data } = await API.get<IndustriesData>(`/v5/industries`)
    return data?.industries
  }
}

export default new IndustriesService()
