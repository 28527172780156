import React from 'react'
import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Modal } from '@nalezhytyi/saldo-ui'
import importantImg from 'assets/images/important.png'
import cn from 'classnames'
import useLocalStorage from 'hooks/useLocalStorage'
import authStore from 'store/mobx/AuthStore'
import Intercom from 'utils/intercomManager'
import chatIcon from 'assets/images/chat_icon.svg'

import classes from './classes.module.scss'

const ImportantUpdateModal: FC = () => {
  const { isStripeConnected } = authStore

  const [showImportantUpdateModal, setShowImportantUpdateModal] =
    useLocalStorage<boolean>('showImportantUpdate', true)

  return (
    <Modal
      className="updateModal"
      open={showImportantUpdateModal && !isStripeConnected}
      size="xs"
    >
      <Modal.Header hideCloseIcon>
        <Modal.Title center bold>
          <h1>Important Update!</h1>
          <Button appearance="subtle" size='sm' className={classes.chat} onClick={() => Intercom.show()}>
            <img src={chatIcon} alt="chat" />
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={cn('text-center', classes.body)}>
        <div className={classes.subtitle}>
          We&apos;re thrilled to introduce an app update that brings exciting
          features.
        </div>
        <div className={cn(classes.infobox, 'mb-16')}>
          Now Payment options and tax settings{' '}
          <strong>are linked to every business entity.</strong>
        </div>
        <img className="full-width mb32" src={importantImg} alt="important" />

        <Button
          block
          appearance="primary"
          size="lg"
          onClick={() => setShowImportantUpdateModal(false)}
        >
          Got it!
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default observer(ImportantUpdateModal)
