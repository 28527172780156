import LocalStorageManager from 'manager/local-storage.manager'
import { useState, useEffect } from 'react'

const useLocalStorage = <T,>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    const value = LocalStorageManager.get<T>(key)

    if (typeof value === 'undefined' || value === null) {
      return defaultValue
    }

    return value
  })

  useEffect(() => {
    LocalStorageManager.set<T>(key, value)
  }, [value, key])

  return [value, setValue]
}

export default useLocalStorage
