import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { createContext } from 'react'
import paymentService from 'services/payment-service/payment.service'
import {
  AccountUpdateData,
  Integration,
  LegalEntityStripeAction,
  PaypalIntegration,
  StripeAccountResponse,
} from 'services/payment-service/types'
import { toast } from 'react-toastify'

export class PaymentStore {
  isLoading: boolean
  isLoadingIntergation: boolean
  stripeAccountData: StripeAccountResponse | null
  paypalAccountData: PaypalIntegration | null
  integrationSettings: any

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      isLoadingIntergation: observable,
      stripeAccountData: observable,
      addStripeAccount: action,
      addPaypalAccount: action.bound,
      removeStripeAccount: action,
      updateStripeAccount: action,
      getIntergations: action,
      updateIntegrationSettings: action,
      togglePaymentLink: action.bound,
      togglePassFees: action.bound,
      isStripeConnected: computed,
      integrationSettings: observable,
    })

    this.isLoading = false
    this.isLoadingIntergation = false
    this.stripeAccountData = null
    this.paypalAccountData = null
    this.integrationSettings = {
      paypal: {
        paymentLinkEnabled: false,
        passFeesToClients: false,
      },
      stripe: {
        paymentLinkEnabled: false,
        passFeesToClients: false,
      },
    }
  }

  get isStripeConnected() {
    return this.stripeAccountData?.onboardingCompleted
  }

  get isPaypalConnected() {
    return this.paypalAccountData?.onboardingCompleted
  }

  togglePaymentLink(value: boolean, integration: Integration) {
    this.integrationSettings[integration].paymentLinkEnabled = value
  }

  togglePassFees(value: boolean, integration: Integration) {
    this.integrationSettings[integration].passFeesToClients = value
  }

  async addStripeAccount(legalEntityId: string) {
    try {
      runInAction(() => {
        this.isLoadingIntergation = true
      })
      toast.info('Wait a moment, link to stripe is generating')

      const link = await paymentService.connectStripe(legalEntityId)

      if (link) {
        window.open(link, '_self')
      } else {
        toast.error('stripe get link error')
      }
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.isLoadingIntergation = false
      })
    }
  }

  async addPaypalAccount(legalEntityId: string) {
    try {
      runInAction(() => {
        this.isLoadingIntergation = true
      })
      toast.info('Wait a moment, link to Paypal is generating')

      const link = await paymentService.conectPaypal(legalEntityId)

      if (link) {
        window.open(link, '_self')
      } else {
        toast.error('stripe get link error')
      }
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.isLoadingIntergation = false
      })
    }
  }

  async getIntergations(legalEntityId: string) {
    this.isLoading = true
    try {
      const [stripe, paypal] = await Promise.all([
        paymentService.getStripeAccount(legalEntityId),
        paymentService.getPaypalIntegration(legalEntityId),
      ])

      runInAction(() => {
        this.stripeAccountData = stripe
        this.paypalAccountData = paypal
        this.updateIntegrationSettings('stripe', stripe)
        this.updateIntegrationSettings('paypal', paypal)
      })
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  updateIntegrationSettings(
    integration: Integration,
    data: AccountUpdateData | null
  ): void {
    if (data) {
      this.integrationSettings[integration] = {
        paymentLinkEnabled: data.paymentLinkEnabled,
        passFeesToClients: data.passFeesToClients,
      }
    } else {
      this.integrationSettings[integration] = {
        paymentLinkEnabled: false,
        passFeesToClients: false,
      }
    }
  }

  async updatePaypalAccount(legalEntityId: string) {
    this.isLoading = true
    try {
      await paymentService.updatePaypalAccounts(
        legalEntityId,
        this.integrationSettings.paypal
      )
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async updateStripeAccount(legalEntityId: string) {
    this.isLoading = true
    try {
      await paymentService.updateStripeAccount(
        legalEntityId,
        this.integrationSettings.stripe
      )
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async removeStripeAccount(legalEntityId: string) {
    try {
      toast.info('Wait a moment, disconnecting your Stripe account')

      await paymentService.updateStripe(
        legalEntityId,
        LegalEntityStripeAction.DISCONNECT
      )
      this.getIntergations(legalEntityId)
    } catch (err) {
      console.error(err)
    }
  }

  async removeGlobalStripeAccount(customerId: string) {
    try {
      toast.info('Wait a moment, disconnecting your Stripe account')

      await paymentService.disconnectGlobalStripe(customerId)
      toast.success('Succesfully disconected stripe')
    } catch (err) {
      console.error(err)
    }
  }

  async linkGlobalStripeAccount(legalEntityId: string) {
    try {
      toast.info('Wait a moment, stripe is connecting')

      await paymentService.updateStripe(
        legalEntityId,
        LegalEntityStripeAction.LINK_EXISTING_STRIPE_TO_LEGAL_ENTITY
      )
      toast.success('Succesfully conected stripe')
    } catch (err) {
      console.error(err)
    }
  }
}

const paymentStore = new PaymentStore()
export const paymentStoreContext = createContext(paymentStore)
export default paymentStore
