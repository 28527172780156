import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "@nalezhytyi/saldo-ui";
import { observer } from "mobx-react";
import invoicesStore from "store/mobx/InvoicesStore";
import { useAmplitude } from "react-amplitude-hooks";
import priceShield from "assets/images/price-icon.svg";
import priceCheck from "assets/images/price-check-icon.svg";
import lock from "assets/images/lock-icon.svg";
import Lottie from "lottie-react";
import successAnimation from "./check.json";
import { CardForm } from "./CardForm";
import settingsStore from "store/mobx/SettingsStore";
import authStore from "store/mobx/AuthStore";
import { useHistory } from "react-router";

import css from './styles.module.scss'
import useNotification from "scripts/hooks/useNotification";

export const Paywall: FC = observer(() => {
  const { isPayWall, isPayWallSource} = invoicesStore;
  const { isHardPaywall, currentStripePlan } = settingsStore;
  const notification = useNotification()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessSubscribe, setIsSuccessSubscribe] = useState<boolean>(false);
  const history = useHistory();

  const { logEvent } = useAmplitude()

  useEffect(() => {
    if (isPayWall) {
      logEvent("subscription_paywallShown", {
        Source: isPayWallSource || "Undefined",
      });
      if (isPayWallSource === "Create Button") {
        history.push("/all-documents/INV");
      }
    }
  }, [isPayWall])

  return (
    <>
      <Modal
        size="sm"
        onClose={() => invoicesStore.setIsPaywall(false)}
        open={isPayWall}
        dialogClassName="paywallModal"
        backdrop={isHardPaywall ? "static" : true}
      >
        <Modal.Header hideCloseIcon={isHardPaywall}>
          <Modal.Title center bold>
            <h1 className={css.paywallTitle}>Invoice Maker PRO</h1>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={css.paywallBody}>
            <div className={css.priceBlock}>
              <div className={css.priceHead}>
                <div className={css.price}>
                  ${currentStripePlan.price}/<span>mo</span>
                </div>
                <div className={css.period}>Billed monthly</div>
              </div>
              <div className={css.priceFoot}>
                <div>
                  <img src={priceShield} alt="shield" />
                  14-day money-back guarantee
                </div>
                <div>
                  <img src={priceShield} alt="shield" />
                  cancel any time, no contract needed
                </div>
              </div>
            </div>
            <div className={css.featuresBlock}>
              <div>
                <img src={priceCheck} alt="check" />
                Unlimited Invoices
              </div>
              <div>
                <img src={priceCheck} alt="check" />
                Unlimited Clients
              </div>
              <div>
                <img src={priceCheck} alt="check" />
                Receive Payments Online
              </div>
              <div>
                <img src={priceCheck} alt="check" />
                Custom Branding
              </div>
              <div>
                <img src={priceCheck} alt="check" />
                Keep Data Safe In Cloud
              </div>
              <div>
                <img src={priceCheck} alt="check" />
                Access To Mobile Apps
              </div>
            </div>
          </div>
          <CardForm
            setIsLoading={setIsLoading}
            actionEnabled={!isLoading}
            onSave={async (token) => {
              logEvent('subscription_subscribe_clicked', {
                Source: 'Paywall',
              })

              if (!token) {
                notification.push('Invalid credentials.', {
                  type: 'error',
                })
                setIsLoading(false)

                return
              }

              notification.push(
                'Subscription is connecting, wait a while.',
                { type: 'info' }
              )

              await settingsStore.subscribeStripe(token, async () => {
                logEvent("subscription_subscribedSuccessfully", {
                  Source: "Paywall",
                });

                if (typeof window.fbq === 'function') {
                  window.fbq('track', 'Purchase', {
                    value: currentStripePlan.price,
                    isHardPaywall,
                    Source: "Paywall",
                  });
                }

                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: 'eventTracking',
                  category: 'Subscription',
                  action: 'Successfully Subscribed',
                  label: '',
                })

                notification.push(
                  'Subscription is successfully connected.',
                  { type: 'info' }
                )
                await authStore.getUser()
                invoicesStore.setIsPaywall(false)
                setIsSuccessSubscribe(true)
              })
              setIsLoading(false)
            }}
          />
        </Modal.Body>
        <Modal.Footer className={css.paywallFooter}>
          <img src={lock} alt="lock" />
          This is a secure SSL encrypted payment
        </Modal.Footer>
      </Modal>
      <Modal
        size="sm"
        onClose={() => setIsSuccessSubscribe(false)}
        open={isSuccessSubscribe}
        dialogClassName="paywallModal"
      >
        <Modal.Body style={{ padding: 0 }}>
          <Lottie
            animationData={successAnimation}
            className={css.successAnimation}
            loop={false}
          />
          <h1 className="text-center">Thank you!</h1>
          <div className={css.successModalDescription}>
            You’ve successfully upgraded
            <br /> to Invoice Maker PRO
          </div>
          <Button
            appearance="primary"
            size="md"
            block
            className={css.paywallButton}
            onClick={() => setIsSuccessSubscribe(false)}
          >
            Done
          </Button>
        </Modal.Body>
      </Modal>
    </>
  )
})
