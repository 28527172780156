const APP_ID = process.env.REACT_APP_INTERCOM_ID;

class IntercomManager {
  static boot = (): void => {
    if (window.Intercom) {
      window.Intercom("boot", {
        app_id: APP_ID,
        businessDomain: "IM",
        purchaseState: "free",
      });
    }
  };

  static loginUser = (userAttributes: Record<string, unknown>): void => {
    if (window.Intercom) {
      window.Intercom("boot", {
        app_id: APP_ID,
        ...userAttributes,
      });
    }
  };

  static show = (): void => {
    if (window.Intercom) {
      window.Intercom("show");
    }
  };

  static shutDown = (): void => {
    if (window.Intercom) {
      window.Intercom("shutdown");
    }

    IntercomManager.boot();
  };
}

export default IntercomManager;
