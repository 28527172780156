import React, { FC } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import authStore from 'store/mobx/AuthStore'
import settingsStore from 'store/mobx/SettingsStore'
import { Button } from '@nalezhytyi/saldo-ui'
import Icon from 'components/Icon'

export interface GoogleAuthProps extends RouteComponentProps {
  disabled?: boolean
  gtmClassName?: string
  onClickHandler: () => void
  onSuccessHandler: (isNewUser: boolean) => void
  iconButton?: boolean
}

const GoogleAuth: FC<GoogleAuthProps> = observer(
  ({
    disabled,
    onClickHandler,
    onSuccessHandler,
    gtmClassName,
    iconButton,
  }) => {
    const onClick = async () => {
      if (disabled) return
      onClickHandler()

      await authStore.singIn('google', onSuccessHandler)
      await settingsStore.getSettings()
    }
    if (iconButton)
      return (
        <Button
          block
          onClick={onClick}
          disabled={disabled}
          style={{
            border: '1px solid rgba(219, 223, 229, 1)',
            background: 'rgba(255, 255, 255, 1)',
            textAlign: 'center',
            padding: '14px',
          }}
        >
          <Icon
            className="fz18"
            name="brands.google-color"
            style={{ verticalAlign: 'middle' }}
          />
          <div className={'gtm_button ' + gtmClassName} />
        </Button>
      )

    return (
      <Button block appearance="primary" onClick={onClick} disabled={disabled}>
        <Icon className="fz18" style={{ float: 'left' }} name="brands.google" />
        <span>Continue with Google</span>
        <div className={'gtm_button ' + gtmClassName} />
      </Button>
    )
  }
)

export default withRouter(GoogleAuth)
