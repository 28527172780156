import { API } from 'services/http.service'
import { IClient, IClientsData, IFilterModel } from './types'

const ENDPOINT = '/v5/{accountEntityId}/clients'

class ClientsService {
  async postClient(data: IClient) {
    return await API.post(ENDPOINT, {
      data,
    })
  }

  async getClient(params: IFilterModel) {
    const { data } = await API.get<IClientsData>(ENDPOINT, { params })
    return data
  }
}
export default new ClientsService()
