import { makeObservable, action, observable, runInAction } from "mobx";
import { createContext } from "react";
import helpers from "utils/helpers";
import {
  CURRENCIES,
  IAMOUNTFORMATVAL,
  ICURFORMATVAL,
  ICURRENCYVAL,
} from "data/settings";
import { IProfileData } from 'services/profile-service/types'
import profileService from 'services/profile-service/profile.service'
import subscriptionService from 'services/subscription-service/subscription.service'
import Cookies from "js-cookie";
import { toast } from "react-toastify";

enum PAYWALL_TYPE {
  HARD_PAYWALL = "1",
}

enum MARKETING_TYPE {
  ELEVEN_99 = "1",
}

export class SettingsStore {
  settings: IProfileData
  subscriptionIsActive: boolean
  isLoading: boolean

  constructor() {
    makeObservable(this, {
      settings: observable,
      subscriptionIsActive: observable,
      isLoading: observable,
      updateCurrency: action,
      getSettings: action,
      subscribeStripe: action,
      clearSettings: action,
      updatePayMethods: action,
      updateCurrencyFormat: action,
      setPRMode: action,
      updateAmountFormat: action,
    });
    this.settings = {
      id: "",
      email: "",
      currency: helpers?.getCurrency() || {
        code: "USD",
        symbol: "$",
        currency: "United States Dollar",
      },
      currencyFormat: helpers?.getCurrencyFormat() || {
        key: "EURO",
        holder: "$0.99",
        title: "European standard",
      },
      amountFormat: helpers?.getAmountFormat() || {
        key: "COMADOT",
        holder: "1,999.00",
        thousandSeparator: ",",
        decimalSeparator: ".",
      },
      restrictProcessingMode: helpers?.getRestrictProcessingMode() || false,
    };
    this.subscriptionIsActive = false;
    this.isLoading = false;
  }

  get marketingType() {
    return Cookies.get("m-type");
  }

  get paywallType() {
    return Cookies.get("p-type");
  }

  get isHardPaywall() {
    return this.paywallType === PAYWALL_TYPE.HARD_PAYWALL;
  }

  get currentStripePlan() {
    switch (this.marketingType) {
      case MARKETING_TYPE.ELEVEN_99:
        return { price: 11.99, planId: process.env.REACT_APP_STRIPE_PLAN_ID_11_99 as string };

      default:
        return { price: 4.99, planId: process.env.REACT_APP_STRIPE_PLAN_ID_4_99 as string };
    }
  }

  async subscribeStripe(token?: string, successHandler?: () => Promise<any>) {
    try {
      if (!token) {
        return;
      }
      runInAction(() => {
        this.isLoading = true
      })

      await subscriptionService.subscribe({
        token,
        ...this.currentStripePlan,
        currency: 'USD',
        appSource: 'INVOICE_MAKER_2',
      })

      if (successHandler) {
        await successHandler()
      }
    } catch (error) {
      console.error(error)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async addStripeCard(customer: string, token: string, onSuccess?: () => void) {
    try {
      runInAction(() => {
        this.isLoading = true
      })

      await subscriptionService.addCard({
        token,
        customer,
      })

      onSuccess?.()
      toast.info('adding card successful')
    } catch (error) {
      console.error(error)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  setPRMode(val: boolean) {
    localStorage.setItem('IM_restrict_processing_mode', String(val))
    this.settings.restrictProcessingMode = val
  }

  async updatePayMethods(customer: string, cardId: string) {
    try {
      runInAction(() => {
        this.isLoading = true
      })

      await subscriptionService.updateCard({
        cardId,
        customer,
      })

      toast.info('choose card successful')
    } catch (error) {
      console.error(error)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async cancelSubscription(subscriptionId: string) {
    try {
      runInAction(() => {
        this.isLoading = true
      })

      await subscriptionService.subscriptionCancel({
        subscriptionId,
      })

      toast.info('cancel subscription successful')
    } catch (error) {
      console.error(error)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async getSettings(onSuccess?: () => void) {
    try {
      runInAction(() => {
        this.isLoading = true
      })

      const data = await profileService.getProfile()

      if (data?.id) {
        localStorage.setItem('IM_userID', data.id)

        runInAction(() => {
          this.settings = { ...this.settings, id: data.id, email: data.email }
        })

        onSuccess?.()
      } else {
        const data = await profileService.registerProfile()
        if (data?.id) {
          localStorage.setItem('IM_userID', data.id)

          runInAction(() => {
            this.settings = {
              ...this.settings,
              id: data.id,
              email: data.email,
            }
          })

          onSuccess?.()
        } else {
          toast.error('profile creation error')
        }
      }

      if (!localStorage.getItem('IM_amount_format')) {
        this.updateAmountFormat(helpers.getAmountFormat())
      }

      if (data?.currency && CURRENCIES[data?.currency]) {
        const newCurrency = CURRENCIES[data?.currency]
        runInAction(() => {
          this.settings.currency = newCurrency
        })
        localStorage.setItem('IM_currency_code', newCurrency.code)
        localStorage.setItem('IM_currency_symbol', newCurrency.symbol)
      }
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async updateCurrency(newCurrency: ICURRENCYVAL) {
    localStorage.setItem('IM_currency_code', newCurrency.code)
    localStorage.setItem('IM_currency_symbol', newCurrency.symbol)

    await profileService.updateProfile({
      currency: { code: newCurrency.code, symbol: newCurrency.symbol },
    })

    this.settings = { ...this.settings, currency: newCurrency }
  }

  updateCurrencyFormat(newCurrencyFormat: ICURFORMATVAL) {
    localStorage.setItem('IM_currency_format', newCurrencyFormat.key)
    this.settings = { ...this.settings, currencyFormat: newCurrencyFormat }
  }

  updateAmountFormat(newAmountFormat: IAMOUNTFORMATVAL) {
    localStorage.setItem('IM_amount_format', newAmountFormat.key)
    this.settings = { ...this.settings, amountFormat: newAmountFormat }
  }

  clearSettings() {
    this.settings = {
      id: '',
      email: '',
      currency: helpers?.getCurrency() || {
        code: 'USD',
        symbol: '$',
        currency: 'United States Dollar',
      },
      currencyFormat: helpers?.getCurrencyFormat() || {
        key: 'EURO',
        holder: '$0.99',
        title: 'European standard',
      },
      amountFormat: helpers?.getAmountFormat() || {
        key: 'COMADOT',
        holder: '1,999.00',
        thousandSeparator: ',',
        decimalSeparator: '.',
      },
      restrictProcessingMode: helpers?.getRestrictProcessingMode() || false,
    }
  }
}

const settingsStore = new SettingsStore()
export const settingsStoreContext = createContext(settingsStore)
export default settingsStore
