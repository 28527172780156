import { makeObservable, action, observable, runInAction } from 'mobx'
import { createContext } from 'react'
import itemsService from 'services/items-service/items.service'
import { IFilterModel, IItem, IItemsData } from 'services/items-service/types'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'

const initialEditorData = {
  name: '',
  price: 0,
  isTaxable: false,
  createdAt: Date.now(),
  updatedAt: Date.now(),
  tax: {
    name: '',
    rate: 0,
  },
}

export class ItemsStore {
  itemsData: IItemsData
  filterModel: IFilterModel
  editorData: IItem
  edited: boolean
  isEditorOpen: boolean
  isLoading: boolean

  constructor() {
    makeObservable(this, {
      itemsData: observable,
      filterModel: observable,
      editorData: observable,
      isLoading: observable,
      edited: observable,
      isEditorOpen: observable,
      toggleEditor: action,
      closeEditor: action,
      changeData: action.bound,
      edit: action,
      create: action,
      getItems: action,
      resetItems: action,
      updateFilter: action,
      postItem: action,
    })

    this.itemsData = {
      offset: 0,
      total: 0,
      results: [],
    }
    this.filterModel = {
      sortBy: 'createdAt',
      search: undefined,
      offset: 0,
      limit: 10,
      sortType: 'DESC',
    }
    this.editorData = { ...initialEditorData }
    this.edited = false
    this.isEditorOpen = false
    this.isLoading = false
  }

  toggleEditor = () => {
    this.isEditorOpen = !this.isEditorOpen
  }

  closeEditor = () => {
    this.edited = false
    this.isEditorOpen = false
  }

  async edit(id?: string | number) {
    const curItem = this.itemsData?.results?.find((i) => i.id === id)
    this.editorData = { ...this.editorData, ...curItem }
    this.toggleEditor()
  }

  create() {
    this.editorData = { ...initialEditorData }
    this.toggleEditor()
  }

  changeData(value: any, key?: string, nestedKey?: string) {
    this.edited = true
    if (!key) {
      return
    }
    if (key && nestedKey) {
      return (this.editorData[key][nestedKey] = value)
    }
    this.editorData[key] = value
  }

  async postItem(
    data: IItem | any,
    isCreate?: boolean,
    successHandler?: (taxable: boolean) => void
  ) {
    this.isLoading = true

    const payload = {
      ...data,
      createdAt: data.createdAt || Date.now(),
      updatedAt: Date.now(),
      price: data.price ? +data.price : 0,
      id: data.id || uuidv4(),
    }

    if (payload.tax) {
      payload.tax.rate = data.tax?.rate ? +data.tax?.rate : 0
    }

    try {
      await itemsService.postItem(payload)

      if (payload.archived) {
        toast.info(`Item ${payload.name || ''} deleted`)
      } else {
        if (isCreate && successHandler) {
          successHandler(data.isTaxable)
        }
        toast.info(
          `Item ${payload.name || ''} ${isCreate ? 'created' : 'updated'}`
        )
      }
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  getArrBySearch = async (search: string) => {
    const data = await itemsService.getItems({ search })
    return data?.results.map((el) => {
      return {
        label: el.name,
        value: el.name,
        data: el,
      }
    })
  }

  async getItems(_filterModel?: IFilterModel) {
    this.isLoading = true
    if (_filterModel) {
      this.filterModel = { ...this.filterModel, ...(_filterModel || {}) }
    }

    try {
      const data = await itemsService.getItems(this.filterModel)

      runInAction(() => {
        this.itemsData = {
          offset: data?.offset || 0,
          total: data?.total || 0,
          results: data?.results.map((el) => {
            return {
              ...el,
              tax: el.tax
                ? {
                    name: el.tax?.name,
                    rate: el.tax?.rate,
                  }
                : undefined,
            }
          }),
        }
      })
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async updateFilter(_filterModel?: IFilterModel) {
    await this.getItems(_filterModel)
  }

  resetItems() {
    this.itemsData = {
      offset: 0,
      total: 0,
      results: [],
    }
  }

  resetFiltration() {
    this.filterModel = {
      sortBy: 'createdAt',
      search: undefined,
      offset: 0,
      limit: 10,
      sortType: 'DESC',
    }
  }
}

const itemsStore = new ItemsStore()
export const itemsStoreContext = createContext(itemsStore)
export default itemsStore
