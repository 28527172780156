import { runInAction, makeAutoObservable } from 'mobx'
import { createContext } from 'react'
import authService from 'services/auth-service/auth.service'
import { AuthProvider, User } from 'services/auth-service/types'
import invoicesStore from './InvoicesStore'
import Intercom from 'utils/intercomManager'
import LocalStorageManager from 'manager/local-storage.manager'

export class AuthStore {
  email = ''
  emailCode = ''
  isCode = false
  isLoading = false
  isSessionExpired = false
  signInType: string | null = null
  user: User | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get subscriptionIsActive() {
    return !!this.user?.entitlements.some(
      (entitlement) =>
        entitlement.name === 'Invoice Maker Pro' && entitlement.isActive
    )
  }

  get isStripeConnected() {
    return (
      this.user?.stripeIntegration.isActive &&
      this.user.stripeIntegration.onBoardingCompleted
    )
  }

  get subscriptionEntitlement() {
    return this.user?.entitlements.find((el) => el.name === 'Invoice Maker Pro')
  }

  async sendEmail(email: string) {
    try {
      await authService.signInByEmail(email)
      runInAction(() => {
        this.emailCode = ''
        this.isCode = true
      })
    } catch (error) {
      throw error
    }
  }

  async getUser(onSuccess?: () => void) {
    if (!this.signInType) {
      this.signInType = LocalStorageManager.get('signInType')
    }

    try {
      const user = await authService.loadCurrentUser()

      Intercom.loginUser({
        name: user.name,
        email: user.email,
        user_id: `${user.customerUid.toUpperCase()}-IM`,
        ssoUserId: user.id,
        customerId: user.customerId,
        customerUid: user.customerUid,
        businessDomain: 'IM',
        purchaseState: user?.entitlements.some(
          (entitlement: any) =>
            entitlement.name === 'Invoice Maker Pro' && entitlement.isActive
        )
          ? 'paid'
          : 'free',
      })

      onSuccess?.()

      runInAction(() => {
        this.user = user
      })
    } catch (err) {
      console.error(err)
    }
  }

  async init() {
    try {
      const user = await authService.trySingIn()

      if (user) {
        runInAction(() => {
          this.user = user
        })
      }
    } catch (error) {
      runInAction(() => {
        this.user = null
      })
    }
  }

  async sendCode(code: string, onSuccess?: (isNewUser: boolean) => void) {
    try {
      runInAction(() => {
        this.isLoading = true
      })

      const user = await authService.confirmAuth(code)

      LocalStorageManager.set('signInType', 'Email')
      this.signInType = 'Email'

      await this.getUser(() => onSuccess?.(user?.isNew))
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async singIn(provider: AuthProvider, onSuccess?: (isNewUser: boolean) => void) {
    try {
      const user = await authService.singInByProvider({
        provider,
        onLoadToken: () => {
          runInAction(() => {
            this.isLoading = true
          })
        },
      })

      LocalStorageManager.set('signInType', provider)
      this.signInType = provider

      await this.getUser(() => onSuccess?.(user?.isNew))

      runInAction(() => {
        this.user = user
        this.isLoading = false
      })
    } catch (error) {
      console.error('Auth <singIn> :', error)
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  logOut() {
    authService.signOut()
    invoicesStore.resetInvoices()
    localStorage.removeItem('IM_userID')
    this.resetUser()
    LocalStorageManager.clear(['showImportantUpdate'])
    Intercom.shutDown()
  }

  setSessionIsExpired(isOpen: boolean) {
    this.isSessionExpired = isOpen
  }

  resetUser() {
    this.email = ''
    this.emailCode = ''
    this.isCode = false
    this.user = null
  }
}

const authStore = new AuthStore()
export const authStoreContext = createContext(authStore)
export default authStore
